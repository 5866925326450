
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import EmailIcon from '@material-ui/icons/Email';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import Pin from '@material-ui/icons/Room';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import ScheduleIcon from '@material-ui/icons/Schedule';
import DepartureBoardIcon from '@material-ui/icons/DepartureBoard';
import LinkedCameraIcon from '@material-ui/icons/LinkedCamera';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import AssessmentIcon from '@material-ui/icons/Assessment';
import HistoryIcon from '@material-ui/icons/History';
import HomeIcon from '@material-ui/icons/Home';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import ReportIcon from '@material-ui/icons/Report';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import LoginIcon from '@mui/icons-material/Login';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StorageIcon from '@mui/icons-material/Storage';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import DatasetIcon from '@mui/icons-material/Dataset';
import SummarizeIcon from '@mui/icons-material/Summarize';

import {
    Dashboard, AddShoppingCart, InsertChart, SortRounded,
    Public, StoreMallDirectory, Storage, PhotoCamera, Timeline,
    ShoppingBasket, Build, Settings as SettingsFilled, TableChart,
    Autorenew, OndemandVideo, Store
} from '@material-ui/icons';

import { faChartBar, faUsers, faChartArea, faRoute, faTasks, faDoorOpen } from '@fortawesome/free-solid-svg-icons';
const faSignal = ({ iconclass }) => <><FontAwesomeIcon icon={['fas', 'signal']} className={iconclass || "fontsIcon"} /></>
const faWrench = ({ iconclass }) => <><FontAwesomeIcon icon={['fas', 'wrench']} className={iconclass || "fontsIcon"} /></>
const faChart = ({ iconclass }) => <FontAwesomeIcon icon={faChartBar} className={iconclass || "fontsIcon"} />
const faUser = ({ iconclass }) => <FontAwesomeIcon icon={faUsers} className={iconclass || "fontsIcon"} />
const faChartAreaIcon = ({ iconclass }) => <FontAwesomeIcon icon={faChartArea} className={iconclass || "fontsIcon"} />
const faRouteIcon = ({ iconclass }) => <FontAwesomeIcon icon={faRoute} className={iconclass || "fontsIcon"} />
const faTaskIcon = ({ iconclass }) => <FontAwesomeIcon icon={faTasks} className={iconclass || "fontsIcon"} />
const faDoorOpenIcon = ({ iconclass }) => <FontAwesomeIcon icon={faDoorOpen} className={iconclass || "fontsIcon"} />

export const PageIcon = {
    SettingsIcon: SettingsIcon,
    CameraAltIcon: CameraAltIcon,
    EmailIcon: EmailIcon,
    faSignal: faSignal,
    Pin: Store,
    faWrench: faWrench,
    History: ScheduleIcon,
    Home: HomeIcon,
    LocationReorder: AddLocationIcon,
    InTransit: DepartureBoardIcon,
    VistaLocation: LinkedCameraIcon,
    Deliveries: LocalShippingIcon,
    Notifications: NotificationsActiveIcon,
    MaintenanceReport: AssessmentIcon,
    AcostaReports: AssessmentIcon,
    OrderHistory: HistoryIcon,
    Dashboard: Dashboard,
    AreaReports: AssessmentIcon,
    Overview: faChart,
    OutOfStockReport: AddShoppingCart,
    PresalesReport: faUser,
    ExecutiveReport: faChartAreaIcon,
    RouteManagerReport: faRouteIcon,
    Progress: InsertChart,
    LeagueTables: SortRounded,
    EasyComparator: CompareArrowsIcon,
    StatusReport: ReportIcon,
    UnileverReport: Timeline,
    UnileverStatus: StoreMallDirectory,
    SurveyReport: faTaskIcon,
    GlobalReport: Public,
    MasterDataDash: Storage,
    ProductAvailability: ShoppingBasket,
    InFieldHardware: PhotoCamera,
    Operational: Build,
    DataSettings: SettingsFilled,
    SurveyDashboard: TableChart,
    Survey: faTaskIcon,
    Replenishment: Autorenew,
    OrderStatus: AddShoppingCart,
    Tutorials: OndemandVideo,
    TrueGateway: faDoorOpenIcon,
    Reports: PlumbingIcon,
    Login: LoginIcon,
    Data: StorageIcon,
    InFieldIcon: AssignmentLateIcon,
    Currency: AttachMoneyIcon,
    DeviceTracking: AttachMoneyIcon,
    DataIcon: DatasetIcon,
    ImportDataIcon: SummarizeIcon,
    ReportingIcon: PlumbingIcon,
    "outletactions.png": Build,
    MyKPIConfiguratorIcon: SettingsIcon
};
