import UiModel from './ui-model';


const inFieldStatusModel = new UiModel({
    title: "In Field Status",
    defaultSort: 'SerialNumber ASC',
    titleDescription: 'Understand your in-field assets statuses',
    idProperty: 'AssetId',
    readOnly: true,
    addCreatedModifiedColumns: false,
    showPivotExportBtn: true,
    pivotAPI: 'InFieldStatus.ashx',
    template: 'in-field-status-pivot',
    configFileName: 'in-field-status',
    hideBackButton: true,
    preferenceId: 'playbookInFieldStatus',
    columns: [
        { field: "SerialNumber", type: 'string', width: 150, label: "Serial Number", pinned: true },
        { field: "AssetType", type: 'string', width: 100, label: "Asset Type", hide: true },
        { field: "IsChestCooler", type: "boolean", width: 100, label: "Chest Cooler", hide: true },
        { field: "Location", type: 'string', width: 200, label: "Outlet Name" },
        { field: "LocationCode", type: 'string', width: 100, label: "Outlet Code" },
        { field: "Street", type: 'string', width: 100, label: "Street", hide: true },
        { field: "Street2", type: 'string', width: 100, label: "Street 2", hide: true },
        { field: "Street3", type: 'string', width: 100, label: "Street 3", hide: true },
        { field: "PostalCode", type: 'string', width: 100, label: "Postal", hide: true },
        { field: "City", type: 'string', width: 80, label: "City", hide: true },
        { field: "State", type: 'string', width: 80, label: "State", hide: true },
        { field: "Country", type: 'string', width: 80, label: "Country", hide: true },
        { field: "MarketName", type: 'string', width: 100, label: "Market", hide: true },
        { field: "Channel", type: 'string', width: 100, label: "Channel", hide: true },
        { field: "ClassificationName", type: 'string', width: 100, label: "Classification", hide: true },
        { field: "DistributorName", type: 'string', width: 100, label: "Distributor", hide: true },
        { field: "LocationRoute", type: 'string', width: 80, label: "Route", hide: true },
        { field: "PreSellerRoute", type: 'string', width: 150, label: "Pre Seller Route", hide: true },
        { field: "PrimaryRepName", type: 'string', width: 200, label: "Primary Sales Rep Name", hide: true },
        { field: "MDMSerialNumber", type: 'string', width: 150, label: "MDM Hub Serial" },
        { field: "MdmLastPing", type: 'date', width: 150, label: "MDM Last Ping", keepUTC: true },
        { field: "HubBatteryLevel", type: 'number', width: 100, label: "Hub Battery", hide: true },
        { field: "LatestRssi", type: 'number', width: 100, label: "Hub RSSI", hide: true },
        { field: "SmartDeviceSerialNumber", type: 'string', width: 100, label: "Smart Device" },
        { field: "LastPing", type: 'date', width: 150, label: "Smart Device Ping", keepUTC: true },
        { field: "BatteryLevel", type: 'number', width: 230, label: "Camera Battery In Millivolts", hide: true },
        { field: "SmartDeviceRssi", type: 'number', width: 150, label: "Smart Device RSSI", hide: true },
        { field: "DoorOpen", type: 'date', width: 120, label: "Door Open", keepUTC: true },
        { field: "PlanogramName", type: 'string', width: 160, label: "Planogram" },
        { field: "LastImageDateTime", type: 'date', width: 200, label: "Last Image Capture Time", keepUTC: true },
        { field: "AssetStatus", width: 180, label: "Hardware Status", pinned: 'right' },
        { field: "HardwareIssueTypeId", type: "radio", width: 180, label: "Hardware Issue", pinned: 'right', lookup: "HardwareIssueType" },
        { field: "Responsible", type: 'string', width: 100, label: "Responsible", pinned: 'right' },
        { field: "PrimaryAction", type: 'string', width: 150, label: "Primary Action", pinned: 'right' },
        { field: "Age", type: 'number', width: 100, label: "Age", pinned: 'right' },
        { field: "OutletActive", type: 'boolean', width: 100, label: "Outlet Active" }
    ]
});

export default inFieldStatusModel;