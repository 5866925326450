import React, { useEffect, useMemo, useState } from 'react';
import { AppBar, Box, Toolbar, Typography, Hidden, IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import utils from '../../utils'
import SearchIcon from '@material-ui/icons/Search';
import SearchByLocationFilter from '../../components/Common/SearchByLocationFilter';
import { useSelector, useDispatch } from 'react-redux';
import FilterLayout from "../../layout/Filters";
import Operations from '../SubHeaderMenu/operations';
import Export from '../SubHeaderMenu/export';
import Print from '../SubHeaderMenu/print';
import ButtonWithIcon from '../SubHeaderMenu/menuComponent';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation  } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import actions from '../../redux/actions';
import swal from "sweetalert";
import useMobile from '../../utils/useMobile';
import { useStateContext } from "@durlabh/dframework-ui";
import PageTitle from '../PageTitle';
import constants from '../../utils/constants';
const t = utils.t;
const defaultBackButton = { status: false, backRoute: "" };

export default function SecondaryHeader(props) {
    const { open, setOpen } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { stateData } = useStateContext();
    const pageTitle = useSelector(state => state.appReducer.pageTitleDetails);
    const editMode = useSelector(state => state.appReducer.editMode);
    const reduxPageBackButton = useSelector(state => state.appReducer.pageBackButton);
    const statePageBackButton = stateData?.pageBackButton && stateData?.pageTitleDetails ? stateData.pageBackButton : reduxPageBackButton
    const backButton = statePageBackButton || defaultBackButton;
    const { filterButton,
        filterButtonName,
        printButtonName,
        shouldOpen = false,
        searchComponent,
        customButtonsLeft = "",
        customButtonsMiddle = "",
        customButtonsRight = "",
        hidden = { search: false, operation: false, export: false, print: false, filter: false, others: [] } } = useSelector(state => state.appReducer.filtersInHeader) || stateData?.filtersInHeader || {};
    const { t: translate, i18n } = useTranslation();
    const tOpts = { t: translate, i18n };
    const [openClose, setOpenClose] = useState(shouldOpen);
    const [position, setPosition] = useState();
    const isMobile = useMobile()
    const isSingleScreen = constants.routesWithNoChildRoute.includes(location.pathname);
    const isInstallation = constants.frameworkScreens.includes(location.pathname);
    const {
        icon = '', 
        titleHeading = '', 
        titleDescription = '', 
        title = '', 
        showBreadcrumbs = false, 
        breadcrumbs = []
      } = stateData?.pageTitleDetails || {};
      

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [])

    useEffect(() => {
        if (shouldOpen) {
            setOpenClose(shouldOpen);
        }
    }, [shouldOpen])

    useEffect(() => {
        setOpen(false);
    }, [hidden.search])

    useEffect(() => {
        if (isSingleScreen) {
            dispatch({
                type: actions.SET_PAGE_BACK_BUTTON,
                pageBackButton: defaultBackButton
            });
        }
    }, [isSingleScreen, dispatch]);

    function handleScroll() {
        const element = document.getElementById('sub-header').offsetTop;
        setPosition(element)
    }

    const memorizedSearch = useMemo(() => {
        return <Hidden smDown={true}>
            <div className="normal-filter d-flex  justify-content-between">
                <SearchByLocationFilter size='small' />
            </div>
        </Hidden>
    }, [t]);


    const handleOpenClose = (event) => {
        setOpenClose(!openClose);
    }

    const handleBackButton = () => {
        if (!editMode) {
            dispatch({ type: actions.SET_PAGE_BACK_BUTTON, pageBackButton: defaultBackButton });
            history.push(backButton.backRoute);
        } else {
            swal({
                title: t("pending changes", tOpts),
                text: t("You have pending changes! Please Confirm or Cancel the form first.", tOpts),
                icon: "info",
                buttons: {
                  confirm: {
                    text: t("OK", tOpts),
                    value: true,
                  },
                },
              })              
        }
    }

    return (
        <>
            <AppBar className={`lower-menu background-body remove-shadow`} position="sticky" id="sub-header"
                style={{ top: position >= 60 ? '60px' : isMobile.mobile && !isMobile.tablet ? "60px" : "" }}>
                <Toolbar className='no-gutters sub-header-container'>
                    {/* Page tittle section */}
                    <Box className="left-sub-header">
                        {backButton.status &&
                            <IconButton className='back-button' component="label" onClick={handleBackButton}>
                                <ArrowBackIcon />
                            </IconButton>}
                        <Typography className='secondary-title' sx={{ display: { xs: 'none', sm: 'block' } }} >
                            {pageTitle && !isInstallation ?
                                pageTitle : <PageTitle icon={icon} titleHeading={titleHeading} titleDescription={titleDescription} title={title} showBreadcrumbs={showBreadcrumbs} breadcrumbs={breadcrumbs}/>
                            }
                        </Typography>
                        {customButtonsLeft && customButtonsLeft()}
                    </Box>

                    <Box sx={{ flexGrow: 1 }} />
                    {customButtonsMiddle && customButtonsMiddle()}
                    {!hidden.search && <Box id="large-search" sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                        {searchComponent || memorizedSearch}
                    </Box>}

                    <Box className='pr-1 sub-header-buttons'>
                        {customButtonsRight && customButtonsRight()}
                        {!hidden.search &&
                            <ButtonWithIcon
                                size="small"
                                btnClass={"background-theme-blue button-with-icon header-search-button sub-header-button"}
                                showIconOnMobile={true}
                                showLabelOnWeb={true}
                                handleClick={() => setOpen(prev => !prev)}
                                icon={<SearchIcon className="icon-search" />}
                                iconButtonClass="iconButtonClass"
                                showOnMobile={true}
                                label={t(("Search"), tOpts)}
                            />
                        }
                    

                        {/* Print section is commented out as per Giulliano requirement */}
                        {/* {!hidden.print && <Print label={t((printButtonName || "Print"), tOpts)} />} */}
                        {!hidden.filter && <ButtonWithIcon
                            size="small"
                            disabled={!(filterButton)}
                            btnClass={"background-theme-blue sub-header-button Location-Reorder-blue"}
                            showIconOnMobile={true}
                            showLabelOnWeb={true}
                            handleClick={handleOpenClose}
                            icon={<FontAwesomeIcon icon={faFilter} />}
                            showOnMobile={true}
                            iconButtonClass="iconButtonClass"
                            label={t((filterButtonName || "Filters"), tOpts)} />}
                        {hidden?.others?.length > 0 && hidden.others.map((other, key) => {
                            return (<ButtonWithIcon
                                key={key}
                                size="small"
                                btnClass={other?.buttonName === 'Create New Group' || other?.buttonName === 'Create Group' ? "sub-header-btn create-new-group-btn" : "sub-header-button"}
                                variant={other?.variant}
                                showIconOnMobile={true}
                                showIconOnWeb={other?.showIcon}
                                showLabelOnWeb={other?.showLabel}
                                /* Added the disabled attribute */
                                disabled={other?.disable}
                                handleClick={other?.handleClick}
                                icon={other?.icon || ""}
                                showOnMobile={true}
                                iconButtonClass="iconButtonClass"
                                label={t((other?.buttonName || ""), tOpts)} />)
                        })}
                    </Box>
                    {openClose && hidden.filter ? handleOpenClose() : <FilterLayout openClose={openClose} handleOpenClose={handleOpenClose} />}
                </Toolbar>
                {(open && !hidden.search) && <Box className="mobile-search-bar">
                    <Box sx={{ flexGrow: 1 }} />
                    {!hidden.search && <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' } }}>
                        {searchComponent || (<div className="normal-filter d-flex justify-content-between"> <SearchByLocationFilter /> </div>)}
                    </Box>}
                </Box>}
            </AppBar>
        </>
    )
}