import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { makeStyles } from '@material-ui/core';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
} from "@mui/material";
import utils from "../../utils";
import { request, apis } from "../../httpUtil";
import { useSnackbar } from "@durlabh/dframework-ui";
const { t } = utils;

const useStyles = makeStyles({
  container: {
    maxWidth: "1200px",
    width: "1200px",
    margin: "auto",
  },
  errorTableContainer: {
    maxHeight: 250,
  },
  errorRow: {
    backgroundColor: "#ffebee",
  },
  exportContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: 2,
  },
  manageTitle: {
    mt: "12px",
    fontWeight: "bold"
  },
  tableCellDivider: {
    borderRight: '1px solid rgba(224, 224, 224, 1)'
  }

});

const recordCounts = 100000;

const ManageData = ({ importUrl, exportUrl, params, action, exportNote, disable, fetchData, apiRef, data, showExportOption = true }) => {
  const classes = useStyles();
  const { t: translate, i18n } = useTranslation()
  const tOpts = { t: translate, i18n };
  const dispatch = useDispatch();
  const userData = useSelector(state => state.appReducer.userData);
  const isAllowed = userData?.modules.MasterData.Add;
  const [progress, setProgress] = useState(0);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [cols, setCols] = useState([]);
  const snackbar = useSnackbar();

  const [importResults, setImportResults] = useState({
    success: null,
    errors: [],
  });

  const fetchPrefenceData = async () => {
    const response = await request({ url: `${apis.Preference}?action=list`, params: { id: action }, dispatch });
    const globalPreference = response?.preferences?.filter(pref => pref.CreatedByUserId === -1);

    if (globalPreference && globalPreference.length > 0 && globalPreference[0]?.ColumnInfo) {
      const columnInfo = JSON.parse(globalPreference[0].ColumnInfo);
      setCols(columnInfo.cols);
    } else {
      setCols([]);
    }
  }

  useEffect(() => {
    fetchPrefenceData();
  }, []);

  async function exportExcel() {
    if (data?.recordCount > recordCounts) {
      snackbar.showMessage(t('Cannot export more than 60k records, please apply filters or reduce your results using filters', tOpts));
      return;
    }
    else {
      const contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const { orderedFields, columnVisibilityModel, lookup } = apiRef.current.state.columns;
      const columns = {};
      const isPivotExport = false;
      const hiddenColumns = Object.keys(columnVisibilityModel).filter(key => columnVisibilityModel[key] === false);
      const visibleColumns = orderedFields.filter(ele => !hiddenColumns?.includes(ele) && ele !== '__check__' && ele !== 'actions');
      if (visibleColumns?.length === 0) {
        snackbar.showMessage(t('You cannot export while all columns are hidden... please show at least 1 column before exporting', tOpts));
        return;
      }
      visibleColumns.forEach(ele => {
        columns[ele] = { field: ele, width: lookup[ele].width, headerName: lookup[ele].headerName, type: lookup[ele].type, keepUTC: lookup[ele].keepUTC === true, isParsable: lookup[ele]?.isParsable };
      })

      fetchData(isPivotExport ? 'export' : undefined, undefined, contentType, columns, isPivotExport, false, false, true);
    }
  }

  const instructions = {
    Outlet: {
      mandatoryFields: [
        t("Code (unique code of the outlet)", tOpts),
        t("Name (name of the outlet)", tOpts),
        t("City (location, city of the outlet)", tOpts),
        t("Street (location, street of the outlet)", tOpts)
      ],
      previousSteps: [t("Before importing new outlets, creating users that will be part of the import template is required.", tOpts)],
      nextSteps: [t("It is recommended to create asset types and assets after successfully creating new outlets.", tOpts)],
    },
    Asset: {
      mandatoryFields: [
        t("Serial Number (unique number of the asset)", tOpts),
        t("Asset Type (type of the asset; create asset type before referring to it)", tOpts)
      ],
      previousSteps: [
        t("Before importing new assets, creating asset types is required.", tOpts),
        t("Only existing planograms can be assigned to an asset;", tOpts),
        t("assigning a planogram is optional; however, it is required to process an image successfully.", tOpts)
      ],
      nextSteps: [
        t("Now, it is recommended to install cameras.", tOpts)
      ],
    },
    "Asset Type": {
      mandatoryFields: [
        t("Asset Type (type of the asset; required to create an asset in the next step)", tOpts),
        t("Model Number (unique model number of asset type)", tOpts),
        t("Orientation Type (horizontal/vertical)", tOpts),
        t("Door Handle Location (Left/Right/Shelf/Under Shelf/Magnet)", tOpts),
        t("Manufacturer (Manufacturer of the asset type)", tOpts)
      ],
      previousSteps: [t("There is no need for any previous steps to create an asset type.", tOpts)],
      nextSteps: [t("It is recommended to create assets after successfully creating new asset types.", tOpts)],
    },
    "Users": {
      mandatoryFields: [
        t("Email (unique email of the user)", tOpts),
        t("First Name (first name of the user)", tOpts),
        t("Last Name (last name of the user)", tOpts),
        t("Roles", tOpts),
        t("Phone", tOpts),
        t("Preferred Notification Type (Email/Mobile Notification/Dnd/Text)", tOpts)
      ],
      previousSteps: [
        t("Market, classification, distributor, and key account are optional; however, if we want to give specific permissions to the user, these values need to be previously created.", tOpts),
        t("To create a market, classification, distributor, and key account, it is recommended to create outlets with these values.", tOpts)
      ],
      nextSteps: [t("It is recommended to create outlets after successfully creating new users.", tOpts)],
    },
    Product: {
      mandatoryFields: [
        t("Product (name of the product, unique identifier of the product)", tOpts),
        t("Short Name (shortened version of the product name)", tOpts),
        t("SKU (Stock Keeping Unit)", tOpts),
        t("Packaging type (size of the packaging, i.e. 50, 100, 1)", tOpts),
        t("Measurement unit (unit of the packaging, i.e. ml, l, kg, g)", tOpts),
      ],
      previousSteps: [t("It is recommended to prepare images of each product.", tOpts)],
      nextSteps: [t("It is recommended that you share a few well-quality images of each product with CoolR.", tOpts)],
    },
    Planogram: {
      mandatoryFields: [
        t("Replenish Model (Model to generate Replenish Detail)", tOpts),
        t("Planogram (Name of Planogram, Mandatory only when Asset Serial Number is not given)", tOpts),
        t("Asset Serial Number (Serial Number of Asset Planogram should be assigned on)", tOpts),
        t("Product SKU (SKU of the product)", tOpts),
        t("Shelf/Priority (Shelf on which product is placed)", tOpts),
      ],
      previousSteps: [t("It is recommended to Create Asset before assigning them Planogram", tOpts)],
      nextSteps: [t("It is recommended that you share a few well-quality images of each product with CoolR.", tOpts)],
    }
  };


  const renderInstructions = () => {
    const currentInstructions = instructions[action];
    return (
      <>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          {t('Mandatory Fields:', tOpts)}
        </Typography>
        <ul>
          {currentInstructions.mandatoryFields.map((field, index) => {
            const parts = field.match(/(^[^\(]+)(\((.*)\))?/);
            const fieldName = parts[1].trim();
            const fieldDescription = parts[3] ? parts[3].trim() : '';

            return (
              <li key={index}>
                <Typography variant="body2">
                  <strong>{t(fieldName, tOpts)}</strong>
                  {fieldDescription && ` (${t(fieldDescription, tOpts)})`}
                </Typography>
              </li>
            );
          })}
        </ul>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
          {t('Previous steps:', tOpts)}
        </Typography>
        <Typography variant="body2">
          {t(currentInstructions.previousSteps.join(' '), tOpts)}
        </Typography>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
          {t('Next steps:', tOpts)}
        </Typography>
        <Typography variant="body2">
          {t(currentInstructions.nextSteps.join(' '), tOpts)}
        </Typography>
      </>
    );
  };




  const handleDrop = useCallback(async (acceptedFiles) => {

    let formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append("importFile", file);
    });
    setUploadDialogOpen(true);

    const response = await request({
      url: importUrl,
      jsonPayload: true,
      additionalHeaders: {
        "Content-Type": "multipart/form-data"
      },
      params: formData,
      disableLoader: true,
      dispatch,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      }
    });

    if (response) {
      setUploadDialogOpen(false);
      setImportResults((prevResults) => ({
        ...prevResults,
        success: response?.successfulRecords || null,
        errors: response?.errros || response?.errors
      }));
    } else {
      setUploadDialogOpen(false);
      snackbar.showError(t("Error Importing File", tOpts), response.info)
    }
  }, [importUrl]);
  const renderImportResults = () => {
    if (!importResults?.success && importResults?.errors.length === 0) {
      return null;
    }

    return (
      <Box>
        {importResults.success !== null && (
          <Typography variant="subtitle1" color="success.main" mb={2}>
            {t('Successful import:', tOpts)}  {importResults?.success}  {t('record(s) processed', tOpts)}
          </Typography>
        )}
        {importResults?.errors?.length > 0 && (
          <>
            <Typography variant="subtitle1" sx={{ mt: "12px", fontWeight: "bold" }} mb={2}>
              {t('Error breakdown:', tOpts)}
            </Typography>
            <TableContainer component={Paper} className={classes.errorTableContainer}>
              <Table stickyHeader aria-label={t("sticky table", tOpts)}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCellDivider}>
                      {t('Row (#)', tOpts)}
                    </TableCell>
                    <TableCell className={classes.tableCellDivider}>
                      {t('Error Type', tOpts)}
                    </TableCell>
                    <TableCell>
                      {t('Message', tOpts)}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {importResults?.errors.map((error, index) => (
                    <TableRow key={index} className={classes.errorRow}>
                      <TableCell component="th" scope="row" className={classes.tableCellDivider}>
                        {error.rowNumber}
                      </TableCell>
                      <TableCell className={classes.tableCellDivider}>
                        {t(error.error,tOpts)}
                      </TableCell>
                      <TableCell>
                        {error.message}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>

    );
  };


  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    disabled: disable
  });

  return (
    <>
      <Box className={classes.container}>
        {showExportOption &&
          <>
            <Box mt={0}>
              <Typography
                variant="subtitle1"
                sx={{ mb: "12px", fontWeight: "bold" }}
              >
                {t('Export', tOpts)}
              </Typography>
              <Box
                className={classes.exportContainer}
              >
                <Button variant="outlined">
                  <img
                    src={require(`../../assets/images/excel.png`)}
                    onClick={exportExcel}
                    height="80"
                    alt={t("Export", tOpts)}
                  />
                </Button>
                <Typography variant="body2" mt={"12px"} ml={"5px"}>
                  {t(`${exportNote}`, tOpts)}
                </Typography>
              </Box>
            </Box>
            <hr />
          </>}
        {isAllowed && (
          <>
            <Typography variant="subtitle1" sx={{ mt: "12px", fontWeight: "bold" }}>
              {t('Import', tOpts)}
            </Typography>
            <Box
              {...getRootProps()}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mb: 2,
                mt: 2,
                width: "100%",
                border: "2px dashed",
                borderColor: "divider",
                borderRadius: 2,
                bgcolor: "action.hover",
                cursor: "pointer",
              }}
            >
              <input {...getInputProps()} />
              <CloudUploadIcon sx={{ fontSize: 50, color: "action.active" }} />
              <Button variant="text" sx={{ mt: 1 }}>
                {t('Add file', tOpts)}
              </Button>
              <Typography variant="body2">{t('or drop files to upload', tOpts)}</Typography>
            </Box>
            {renderImportResults()}
          </>
        )}
        <Box>
          {renderInstructions()}
        </Box>
      </Box>
      <Dialog open={uploadDialogOpen} onClose={() => { }} maxWidth="lg">
        <DialogTitle>{t('Please Wait...', tOpts)}</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2" gutterBottom>
            {t('Uploading...', tOpts)}
          </Typography>
          <LinearProgress variant="determinate" value={progress} />
        </DialogContent>
      </Dialog>
    </>

  );
};

export default ManageData;
