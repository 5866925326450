
import React from "react";
import ButtonWithIcon from "./menuComponent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport, faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { withTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { withRouter } from "react-router";
import utils from "../../utils";

const t = utils.t;
function Export({ t: trans, i18n }) {
    const tOpts = { t: trans, i18n };
    const { onExportClick } = useSelector(state => state.appReducer.operationsCheckedData) || {};
    const menuItems = [{
        label: t("Excel"),
        Icon: faFileExcel,
        show: true,
        onClick: onExportClick,

    }];
    return <>
        <ButtonWithIcon size="small" disabled={!onExportClick} btnClass={"background-theme-blue sub-header-button"} showIconOnMobile={true} showLabelOnWeb={true} icon={<FontAwesomeIcon icon={faFileExport} />} showOnMobile={true} label={t("Export", tOpts)} menuItems={menuItems} iconButtonClass="iconButtonClass" />
    </>
}

export default withTranslation()(withRouter(Export));