import React from 'react';
import dayjs from 'dayjs';
import utils from '../../utils';
import ChartComponent from '../Chart/muix';

const LineComponent = ({ data, categories, title, multiple = false, isDate = false }) => {
    const palette = Object.values(utils.analysisColorCodes);
    let colors = palette;
    let uSeries, xAxisConfigFromProps;
    const formatDate = (date) => {
        if (!(date instanceof Date) || isNaN(date.getTime())) {
            return 'Invalid Date';
        }
        const format = utils.systemDateTimeFormat(true);
        return dayjs(date).format(format);
    };

    const processSeriesData = (data) => {
        return data.map((entry) => ({
            type: 'line',
            data: entry?.data || [],
            label: entry?.name || '',
            curve: "linear"
        }));
    };

    if (isDate) {
        const uniqueCategories = Array.from(new Set(categories));
        const sortedCategories = uniqueCategories
            .map(item => {
                const [day, month, year] = item.split('-');
                const date = new Date(`${year}-${month}-${day}`);
                return { original: item, date };
            })
            .sort((a, b) => a.date - b.date)
            .map(item => item.original);
        const lineCategories = sortedCategories.map(item => {
            const [day, month, year] = item.split('-');
            const date = new Date(`${year}-${month}-${day}`);
            return isNaN(date.getTime()) ? new Date() : date;
        });
        const seriesData = data?.[0]?.data?.map(item => item.value) || [];
        const series = [{ type: 'line', curve: 'linear', data: seriesData }];
        uSeries = series;
        xAxisConfigFromProps = {
            valueFormatter: formatDate,
            data: lineCategories
        };
        if (multiple) {
            uSeries = processSeriesData(data);
        }
    } else {
        uSeries = processSeriesData(data);
    }
    if (multiple) {
        const seriesDataCat = uSeries.map(item => item.label);
        let lineCategories = seriesDataCat;
        colors = colors;
        for (let i = 0; i < lineCategories.length; i++) {
            if (lineCategories[i] === "Average") {
                colors[i] = "#000";
            } else {
                if (i < colors.length) {
                    colors[i] = colors[i];
                } else {
                    colors[i] = utils.getRandomColor();
                }
            }
        }
    }

    return (
        <ChartComponent
            type="line"
            series={uSeries}
            categories={categories}
            xLabel={`Date Range `}
            yLabel={`No of responses `}
            xAxisConfigFromProps={xAxisConfigFromProps}
            isDate={isDate}
            multiple={multiple}
            title={title}
            rangi={colors}
            margin={{
                top: 105
            }}
        />
    );
};

export default LineComponent;
