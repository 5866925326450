import UiModel from './ui-model';
import enums from '../utils/constants';
import { useSelector } from 'react-redux';

const { temperatureUnit } = enums;

const thresholdEnum = {
    GPSThreshold: 10,
    TemperatureThreshold: 10,
    EmptySOSThreshold: 10,
    ForeignSOSThreshold: 10,
    HardwareStatusThreshold: 'Not Working'
}

const RenderTemperature = ({ value }) => {
    const { tags } = useSelector(state => state.appReducer.userData);
    value = Number(value);
    let temperatureValue = `${value.toFixed(2)} °C`;
    if (parseInt(tags.TempratureUnitId) !== temperatureUnit.Celsius) {
        temperatureValue = `${((value * 9 / 5) + 32).toFixed(2)} °F`;
    }
    const className = value > thresholdEnum.TemperatureThreshold ? 'bold-red' : '';
    return <div className={className}>{temperatureValue}</div>
}

const assetListModel = new UiModel({
    title: "Data",
    titleDescription: 'Data',
    defaultSort: 'SerialNumber ASC',
    idProperty: 'SerialNumber',
    readOnly: true,
    api: 'DataAsset',
    module: 'DataPage',
    addCreatedModifiedColumns: false,
    preferenceId: 'playbookDataAsset',
    columns: [
        { field: "SerialNumber", label: "Serial Number", width: 200 },
        { field: "Location", label: "Outlet Name", width: 150 },
        { field: "LocationCode", label: "Outlet Code", width: 120 },
        {
            field: "Displacement", label: "GPS", type: "number", width: 100, renderCell: (params) => {
                const { value } = params;
                const className = value > thresholdEnum.GPSThreshold ? 'bold-red' : '';
                return <div className={className}>{value}</div>
            }
        },
        {
            field: "Temperature", label: "Temperature", type: "number", width: 100, renderCell: (params) => {
                const { value } = params;
                return value ? <RenderTemperature value={value} /> : null
            }
        },
        {
            field: "EmptyProductPercentage", label: "Empty SoS", type: "number", width: 100, renderCell: (params) => {
                const { value } = params;
                const className = value > thresholdEnum.EmptySOSThreshold ? 'bold-red' : '';
                return <div className={className}>{value}</div>
            }
        },
        {
            field: "ForeignProductPercentage", label: "Foreign SoS", type: "number", width: 100, renderCell: (params) => {
                const { value } = params;
                const className = value > thresholdEnum.ForeignSOSThreshold ? 'bold-red' : '';
                return <div className={className}>{value}</div>
            }
        },
        {
            field: "InfieldAssetStatus", width: 150, label: "Hardware Status", renderCell: (params) => {
                const { value } = params;
                const className = value === thresholdEnum.HardwareStatusThreshold ? 'bold-red' : '';
                return <div className={className}>{value}</div>
            }
        }
    ]
});

export default assetListModel;