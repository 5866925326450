import React, { useEffect } from 'react';
import { Box, TextField, Button, Typography, IconButton, Tooltip } from '@mui/material';
import { useFormik } from 'formik';
import FormGenerator from './FormGenerator';
import { useDispatch, useSelector } from 'react-redux';
import utils from '../../utils';
import { useTranslation } from 'react-i18next';
import actions from '../../redux/actions';
import PageTitle from '../PageTitle';
import HelpIcon from '@mui/icons-material/Help';
import { apis, request } from '../../httpUtil';
import { useHistory } from 'react-router';
import { useSnackbar } from '@durlabh/dframework-ui';
import { makeStyles } from '@material-ui/core';

const initialKpiData = [
    { kpi: 'Own Share of Shelf', is: 'Greater than', value: '', weight: '', suffix: '%' },
    { kpi: 'Foreign Share of Shelf', is: 'Less than', value: '', weight: '', suffix: '%' },
    { kpi: 'Empty Share of Shelf', is: 'Less than', value: '', weight: '', suffix: '%' },
    { kpi: 'Own Shelf Availability', is: 'Greater than', value: '', weight: '', suffix: '%' },
    { kpi: 'Assortment Depth Numeric', is: 'Greater than', value: '', weight: '', suffix: '%' },
    { kpi: 'Assortment Depth Compliance', is: 'Greater than', value: '', weight: '', suffix: '%' },
    { kpi: 'Target Portfolio Compliance', is: 'Greater than', value: '', weight: '', suffix: '%' },
    { kpi: 'Planogram Compliance', is: 'Greater than', value: '', weight: '', suffix: '%' },
    { kpi: 'Displacement', is: 'Less than', value: '', weight: '', suffix: 'm' },
    { kpi: 'Temperature', is: 'Less than', value: '', weight: '', suffix: '°C' }
];

const useStyles = makeStyles({
    boldStyle: { fontWeight: 'bold !important' },
    name: {
        width: '30% !important',
        backgroundColor: 'white'
    },
    reportBtn: {
        marginLeft: '20px !important'
    }
})

const MyKPIConfigurator = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const userData = useSelector(state => state.appReducer.userData);
    const snackbar = useSnackbar();
    const classes = useStyles();
    let totalWeight = 0;
    const kpiConfig = {};
    const initialValues = {
        name: '',
        kpiValues: initialKpiData
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { ...initialValues },
        validateOnBlur: false,
        onSubmit: async (values) => {
            values.kpiValues.forEach(kpi => {
                if (kpi.weight) {
                    totalWeight += parseInt(kpi.weight, 10);
                }

                if (kpi.value && kpi.weight) {
                    kpiConfig[kpi.kpi] = { value: kpi.value, weight: kpi.weight };
                }
            });

            const ClientId = userData?.tags?.ClientId ?? undefined;
            const reqParams = {
                ClientId: ClientId,
                KPIName: values.name,
                KPIConfiguration: kpiConfig
            }
            try {
                const response = await request({ url: apis.MyKPIConfigurator, params: reqParams, history, dispatch, jsonPayload: true });
                if (response.success) {
                    snackbar.showMessage(t("Submitted successfully", tOpts));
                }
            } catch (error) {
                snackbar.showError(error.message);
            }
        }
    });

    const handleSubmit = function (e) {
        e.preventDefault();
        for (const kpi of formik.values.kpiValues) {
            if ((kpi.value && !kpi.weight) || (!kpi.value && kpi.weight)) {
                snackbar.showError(t('Please fill the values on the edited rows', tOpts));
                return;
            }
            totalWeight += parseInt(kpi.weight || '0', 10);
        }
        if (totalWeight !== 100) {
            snackbar.showError(t('The conditions could not be saved. The weight has to be summed to 100%.', tOpts));
            return;
        }
        formik.handleSubmit();
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { ClientId } = userData && userData.tags ? userData.tags : {};
                const { success, data } = await request({ url: apis.MyKPIConfigurator, params: { ClientId }, history, dispatch, jsonPayload: true });
                if (success && data) {
                    const fetchedKPIs = data.KPIConfiguration ? JSON.parse(data.KPIConfiguration) : {};
                    const updatedKpiValues = initialKpiData.map(kpi => {
                        const fetchedKpi = fetchedKPIs && fetchedKPIs[kpi.kpi];
                        return {
                            ...kpi,
                            value: fetchedKpi && fetchedKpi.value ? fetchedKpi.value : kpi.value,
                            weight: fetchedKpi && fetchedKpi.weight ? fetchedKpi.weight : kpi.weight
                        };
                    });
                    formik.setValues({
                        name: data.KPIName || '',
                        kpiValues: updatedKpiValues
                    });
                }
            } catch (error) {
                snackbar.showError(error.message || t('Failed to fetch KPI data', tOpts));
            }
        };

        fetchData();
    }, [userData]);



    const handleNameChange = (event) => {
        formik.setFieldValue('name', event.target.value);
    };

    return (
        <div>
            <Typography className={classes.boldStyle}>{t("My KPI Name", tOpts)}</Typography>
            <TextField
                name="name"
                label="Name"
                variant="outlined"
                fullWidth
                margin="normal"
                inputProps={{ maxLength: 50 }}
                value={formik.values.name}
                onChange={handleNameChange}
                onBlur={formik.handleBlur}
                className={classes.name}
            />
            <Typography className={classes.boldStyle}>{t("Calculation", tOpts)}
                <Tooltip title={"My Kpis"}>
                    <IconButton>
                        <HelpIcon color='primary' />
                    </IconButton>
                </Tooltip>
            </Typography>
            <FormGenerator
                kpiValues={formik.values.kpiValues}
                setFieldValue={formik.setFieldValue}
            />
            <Box mt={2}>
                <Button variant="contained" color="primary" type="submit" onClick={handleSubmit}>{t("Save", tOpts)}</Button>
                <Button variant="contained" color="secondary" className={classes.reportBtn}>{t("Go to My KPI report", tOpts)}</Button>
            </Box>
        </div>
    );
};

export default MyKPIConfigurator;
