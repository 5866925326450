import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import dayjs from 'dayjs';
import { CommonChartsConfig } from './CommonChartsConfig';

//Override the date format in highcharts
Highcharts.setOptions({
    global: {
        useUTC: false
    }
});
const LineChart = ({ title, subtitle, series, dateFormat, xLabel, yLabel }) => {
    CommonChartsConfig();
    const options = {
        title: {
            text: title,
            align: 'left',
        },
        subtitle: {
            text: subtitle,
            align: 'left',
        },
        yAxis: {
            title: {
                text: yLabel,
            },
            allowDecimals: false
        },
        xAxis: {
            type: 'datetime',
            labels: {
                formatter: function () {
                    const date = dayjs(this.value);
                    return date.format(dateFormat);
                }
            },
            title: {
                text: xLabel,
            },
        },
        tooltip: {
            formatter: function () {
                const dateStr = dayjs(this.point.x).format(dateFormat);
                return `${dateStr}`;
            }
        },
        plotOptions: {
            series: {
                showInLegend: false,
                dataLabels: {
                    enabled: false
                },
                label: {
                    connectorAllowed: false
                }
            }
        },
        series,
        credits: {
            enabled: false,
        },
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    }
                },
            ],
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default LineChart;
