import { store } from '../redux/store';

const constants = {
  childRoutes: {
    Home: [
      {
        title: "Location Reorder",
        description: "Control over suggested orders",
        icon: "truck.png",
        route: "/Replenishment/LocationReorder",
        moduleName: "OrderStatus",
        metaData: { "AppId": "1,2,3" }
      },
      {
        title: "Outlets",
        description: "Insights into your outlets, their performance and more",
        icon: "outlet.png",
        route: "/Outlet",
        moduleName: "PlaybookOutlet",
        metaData: { "AppId": "1" }
      },
      {
        page: "InFieldStatus",
        title: "In Field Status",
        type: "page",
        description: "Understand your in-field assets statuses",
        icon: "InFieldStatus.png",
        route: "/InFieldStatus",
        moduleName: "InFieldStatus",
        noRibbon: true,
        metaData: { "AppId": '1' }
      },
      {
        title: "Business Performance",
        description: "Harvest insights to fuel your business",
        icon: "performance.png",
        route: "/BusinessPerformance",
        moduleName: "BusinessPerformance",
        metaData: { "AppId": "1" }
      },
      {
        title: "MasterData",
        description: "Check how your master data is set",
        icon: "master Data.png",
        route: "/MasterData",
        moduleName: "MasterData",
        metaData: { "AppId": "1" }
      },
    ],
    DataAndSettings: {
      General: {
        MasterData: {
          page: "MasterData",
          title: "MasterData",
          description: "Check how your master data is set",
          route: "/MasterData",
          icon: "master Data.png",
          moduleName: "MasterData",
          noRibbon: true,
          metaData: { "AppId": '1' }
        },
        Settings: {
          page: "Settings",
          title: "Settings",
          description: "Control your project settings",
          route: "/Settings",
          icon: "Settings.png",
          moduleName: "MasterData",
          metaData: { "AppId": '1' }
        },
      }
    },
    Operations: {
      AlertOverview: {
        page: "AlertOverview",
        title: "Alert Overview",
        type: "task",
        description: "Check and oversee your current open alerts",
        icon: "overview.png",
        route: "/Operations/AlertOverview",
        moduleName: "AlertOverview",
        metaData: { "AppId": '1' }
      }
    },
    BusinessPerformance: {
      Merchandising: {
        page: "Merchandising",
        title: "Merchandising",
        subtitle: "Insights into the merchandising quality and fill-level of your assets.",
        description: "Insights into the merchandising quality and fill-level of your assets.",
        KPIList: null,
        icon: "merchandising.png",
        route: "/BusinessPerformance/Merchandising",
        moduleName: "Merchandising",
        noRibbon: true,
        metaData: { "AppId": '1' }
      },
      Portfolio: {
        page: "Portfolio",
        title: "Portfolio",
        subtitle: "Understand how your products are being distributed.",
        description: "Understand how your products are being distributed.",
        KPIList: ["Product Distribution", "Product Distribution Compliance"],
        icon: "product.png",
        route: "/BusinessPerformance/Portfolio",
        moduleName: "Portfolio",
        noRibbon: true,
        metaData: { "AppId": '1' }
      },
      SalesAndShopper: {
        page: "SalesAndShopper",
        title: "Sales & Shopper",
        subtitle: "Understand the sales performance of your assets.",
        description: "Understand the sales performance of your assets.",
        KPIList: [
          "Suggested Order Value",
          "Suggested order case count",
          "Average Door Openings",
        ],
        icon: "sales.png",
        route: "/BusinessPerformance/SalesAndShopper",
        moduleName: "Sales-And-Shopper",
        noRibbon: true,
        metaData: { "AppId": '1' }
      },
    },
    Replenishment: {
      LocationReorder: {
        page: "LocationReorder",
        title: "Location Reorder",
        type: "new",
        description: "Control over suggested orders",
        icon: "truck.png",
        route: "/Replenishment/LocationReorder",
        moduleName: "OrderStatus",
        noRibbon: true,
        metaData: { "AppId": '1,2,3' }
      },
      ScheduledOrderEmailLogs: {
        page: "ScheduledOrderEmailLogs",
        type: "new",
        title: "Scheduled Order Logs",
        description: "Check the history of orders scheduled",
        icon: "email.png",
        route: "/Replenishment/ScheduledOrderEmailLogs",
        moduleName: "ScheduledOrderLogs",
        noRibbon: true,
        metaData: { "AppId": '1' }
      },
      SalesHistory: {
        page: "SalesHistory",
        title: "Sales History",
        type: "new",
        description: "Check the sales order history ",
        icon: "saleshistory.png",
        route: "/Replenishment/SalesHistory",
        moduleName: "SalesHistory",
        noRibbon: true,
        metaData: { "AppId": '1' }
      },
      OrderSuggestionHistory: {
        page: "OrderSuggestionHistory",
        title: "Order Suggestion History",
        type: "new",
        description: "Understand the history of the suggestive orders",
        icon: "orderhistory.png",
        route: "/Replenishment/OrderSuggestionHistory",
        moduleName: "OrderSuggestionHistory",
        noRibbon: true,
        metaData: { "AppId": '1' }
      }
    },
    Survey: {
      "Set Up": {
        OutletGroup: {
          page: "OutletGroup",
          title: "Outlet Groups",
          description: "Create and configure groups (clusters) of outlets for use in StoreBuddy",
          route: "/Survey/OutletGroup",
          icon: "Outlet Groups.png",
          moduleName: "OutletGroup",
          subPages: {
            GroupDetails: { title: "" },
            AddOutletGroup: { title: "Add Outlet Group" },
          },
          metaData: { "AppId": '1' }
        },
        UserGroup: {
          page: "UserGroup",
          title: "User Groups",
          description: "Create and configure groups (clusters) of team members for use in StoreBuddy",
          route: "/Survey/UserGroup",
          icon: "User Groups.png",
          moduleName: "UserGroup",
          subPages: {
            UserDetails: { title: "" },
            AddUserGroup: { title: "Add User Group" },
          },
          metaData: { "AppId": '1' }
        },
        Questionnaire: {
          page: "Questionnaires",
          title: "Questionnaires",
          newQuestionnaireTitle: "Create Questionnaire",
          defaultTitle: "Questionnaires",
          description: "Build flexible questionnaires for use in surveys",
          route: "/Survey/Questionnaire",
          icon: "Questionnaires.png",
          moduleName: "Questionnaire",
          currentTitle: function (subPage = " ") {
            if (subPage === "create") {
              return this.newQuestionnaireTitle;
            }
            return this.defaultTitle;
          },
          subPages: {
            create: { title: "Create Questionnaire" },
          },
          metaData: { "AppId": '1' }
        },
        Survey: {
          page: "Survey",
          title: "Surveys",
          newSurveyTitle: "Create Survey",
          reviewPageTitle: "Review",
          frequencyPageTitle: "Recurrence Period",
          description: "Create and configure surveys for use in StoreBuddy",
          route: "/Survey/Survey",
          icon: "Surveys.png",
          moduleName: "Survey",
          currentTitle: function (subPage = " ") {
            if (subPage === "AddSurvey") {
              return this.newSurveyTitle;
            }
            else if (subPage === 'Review') {
              return this.reviewPageTitle;
            }
            else if (subPage === 'FrequencySummary') {
              let reduxStore = store.getState().appReducer;
              let frequencyCycleData = reduxStore.frequencyCycleData;
              return frequencyCycleData?.SurveyName || this.frequencyPageTitle;
            }
            return this.defaultTitle;
          },
          subPages: {
            Review: { title: "Review and Publish" },
            AddSurvey: { title: "Add survey" },
            FrequencySummary: { title: "Reccurrence Period" },
          },
          metaData: { "AppId": '1' }
        }
      },
      Report: {
        Dashboard: {
          page: "Dashboard",
          title: "Dashboard",
          description: "Understand overall numbers for survey",
          icon: "dashboard.png",
          route: "/Survey/Dashboard",
          moduleName: "SurveyDashboard",
          metaData: { "AppId": '1' }
        },
        Report: {
          page: "Report",
          title: "Survey Inbox",
          description: "Review submitted surveys individually",
          icon: "report.png",
          route: "/Survey/Report",
          moduleName: "SurveyReport",
          metaData: { "AppId": '1' }
        },
        Analysis: {
          page: "Analysis",
          title: "Results Analysis",
          description: "Review survey results with options to filter, aggregate, export and more",
          icon: "report.png",
          route: "/Survey/Analysis",
          moduleName: "SurveyAnalysis",
          metaData: { "AppId": '1' },
          subPages: {
            QuestionWiseAnalysis: { title: "Question Wise Analysis" },
          }
        },
        Operations: {
          page: "Operations",
          title: "Operational Dashboard",
          description: "Visualize how users and outlets are being surveyed",
          icon: "report.png",
          route: "/Survey/Operations",
          moduleName: "SurveyOperations",
          metaData: { "AppId": '1' }
        },
        Inbox: {
          page: "SurveyInbox",
          title: "Survey Inbox WIP",
          description: "Review submitted surveys individually",
          icon: "report.png",
          route: "/Survey/SurveyInbox",
          moduleName: "SurveyInbox",
          metaData: { "AppId": '1' },
        },
      },
    },
    TrueGateway: {
      TrueDashboard: {
        page: "TrueDashboard",
        title: "True Dashboard",
        description: "True Dashboard",
        route: "/TrueGateway/TrueDashboard",
        icon: "dashboard.png",
        moduleName: "TrueDashboard",
        metaData: { "AppId": '1' }
      },
    },
  },
  wrongAction:
    "Error Occurred, Either wrong action selected or you do not have permission to view this page, Please Contact admin.",
  groupByOptions: [
    { label: "Market", dataKey: "MarketId", value: "MarketId" },
    {
      label: "Classification",
      dataKey: "ClassificationId",
      value: "ClassificationId",
    },
    { label: "Channel", dataKey: "LocationTypeId", value: "LocationTypeId" },
  ],
  groupByOptionsIB: [
    { DisplayValue: "Market", LookupId: "MarketId" },
    { DisplayValue: "Classification", LookupId: "ClassificationId" },
    { DisplayValue: "Channel", LookupId: "LocationTypeId" },
    { DisplayValue: "Sales Rep", LookupId: "PrimaryRepId" },
  ],
  periods: [
    { LookupId: "WTD", DisplayValue: "This Week" },
    { LookupId: "MTD", DisplayValue: "Month to Date" },
    { LookupId: "QTD", DisplayValue: "Quarter to Date" },
    { LookupId: "YTD", DisplayValue: "Year to Date" },
  ],
  prPeriodOptions: [
    { LookupId: "Week", DisplayValue: "Week" },
    { LookupId: "Month", DisplayValue: "Month" },
    { LookupId: "Quarter", DisplayValue: "Quarter" }
  ],
  prNPeriodOptions: [
    { LookupId: "2", DisplayValue: "2" },
    { LookupId: "4", DisplayValue: "4" },
  ],
  groupByDisplayName: {
    MarketId: "Market",
    ClassificationId: "Classification",
    LocationTypeId: "Channel",
    "Location.LocationId": "Location",
    "AssetPurity.ClientId": "Client",
    PrimaryRepId: "Sales Rep",
  },
  dateFormat: {
    date: "DD MMM YYYY",
    month: "DD MMM",
    monthSingle: "D MMM",
    ddmmyy: "DD-MM-YYYY",
    yymmdd: "YYYY-MM-DD"
  },
  customOrder: [
    "Assets Covered",
    "Own Share of Shelf",
    "Foreign Share of Shelf",
    "Empty Share of Shelf",
    "On Shelf Availability",
    "Assortment Depth Numeric",
    "Assortment Depth Compliance",
    "Target Portfolio Compliance",
    "Product Category Compliance",
    "Planogram Compliance",
    "Average Door Openings",
    "Suggested order case count",
    "Suggested Order Value",
    "Occupancy",
    "Availability",
    "Coke %",
    "Purity",
    "Serve Pack",
    "Overall Grade",
  ],
  outletDisplacementThreshold: 500,
  OrderStatusLookUp: [
    { LookupId: 0, DisplayValue: "Inbox" },
    { LookupId: 5485, DisplayValue: "Scheduled" },
    { LookupId: 5486, DisplayValue: "Ignored" },
    { LookupId: 5484, DisplayValue: "In-Transit" },
  ],
  CasesOrderLoopUp: [
    { LookupId: "asc", DisplayValue: "Lowest to highest" },
    { LookupId: "desc", DisplayValue: "Highest to lowest" },
  ],
  allOrdersStatus: [0, 5485, 5486, 5484],
  turkeyOrderValidateModule: "TurkeyScheduleVerify",
  turkeyOrderValidateKey:
    "HkXNxaigN8LAcMnjbYCFZwu08U4Tgm6fWR8YI1eJXOkEGzPCqQADVmh7Yo3vAODw",
  imageGridBox: "image",
  salesAndShopper: [
    {
      checked: true,
      dataKey: "DoorOpenDailyCount",
      label: "Average Door Openings",
      metricsValue: "Avg",
      category: "Sales",
      subCategory: "Sales",
      ranges: [],
    },
    {
      checked: true,
      dataKey: "CaseSuggested",
      label: "Suggested order case count",
      metricsValue: "Avg",
      category: "Cases",
      subCategory: "Cases",
      ranges: [],
    },
    {
      checked: true,
      dataKey: "ValueSuggested",
      label: "Suggested Order Value",
      metricsValue: "Avg",
      category: "Value",
      subCategory: "Value",
      ranges: [],
    },
  ],
  temperatureCombo: [
    { LookupId: 0, DisplayValue: "Celsius" },
    { LookupId: 1, DisplayValue: "Fahrenheit" },
  ],
  booleanCombo: [
    { LookupId: 0, DisplayValue: "No" },
    { LookupId: 1, DisplayValue: "Yes" },
  ],
  booleanCombo: [
    { LookupId: false, DisplayValue: "No" },
    { LookupId: true, DisplayValue: "Yes" },
  ],
  freshChatScript: "//fw-cdn.com/2222021/2890971.js",
  hidePriceForClients: ['390'],
  roles: {
    storeOwner: [104]
  },
  UnpackUnitLabelId: 7619,
  UnitLabel: {
    packLabel: "Pack"
  },
  noneDateFilter: {
    LookupId: "-2", DisplayValue: "None"
  },
  defaultPermissions: {
    add: true,
    edit: true,
    export: true,
    delete: true,
    filter: true
  },
  clearFilterText: "CLEAR FILTER",
  gridFilterModel: { items: [], logicOperator: 'and', quickFilterValues: Array(0), quickFilterLogicOperator: 'and' },
  dateFields: ['date', 'dateTime'],
  dynamicOptionType: {
    "None": [
      {
        "id": 1,
        "SerialNumber": "",
        "AssetType": "",
        "Location": "",
        "RowNumber": 1
      }
    ],
    "For Assets in Store": "assets",
    "For Products Total": "products",
    "For Products in Asset": "asset_products",
    "For Products in Store": "store_products"
  },
  previewRepeatType: {
    [
      {
        "id": 1,
        "SerialNumber": "",
        "AssetType": "",
        "Location": "",
        "RowNumber": 1
      }
    ]: 0,
    "assets": 1,
    "products": 2,
    "asset_products": 3,
    "store_products": 4
  },
  repeatType: {
    "Auto repeat": 1,
    "Prompted": 2
  },
  defaultQuestionText: "Enter the question text here...",
  defaultOptionText: "Enter text for this option...",
  chartFilterFields: {
    SerialNumber: "SerialNumber",
    AssetType: "AssetType",
    Code: "LocationCode",
    MDMSerialNumber: "MDMSerialNumber",
    SmartDeviceSerialNumber: "SmartDeviceSerialNumber",
    PlanogramName: "PlanogramName"
  },
  questionnaireDesignView: 'design',
  questionTypesWithDynamicOptions: ["Dropdown", "checkbox", "radio", "select"],
  locationReorderExportFields: {
    OrderSuggestion: 'OrderSuggestion',
    LastImageDate: 'LastImageDate',
    LastOrderIntransit: 'LastOrderIntransit',
    LastOrderIntransitDate: 'LastOrderIntransitDate',
    ReorderDate: 'ReorderDate',
    ReorderDateText: 'ReorderDateText',
    LastDeliveryDate: 'LastDeliveryDate',
    LastDeliveryDateString: 'LastDeliveryDateString',
    LastOrderDate: 'LastOrderDate',
    LastOrderDateString: 'LastOrderDateString'
  },
  previewTabs: ["General section", "Dynamic section"],
  acostaStatus: {
    1: 'New',
    2: 'Planned',
    254: 'Complete',
    255: 'Closed',
    256: 'Acknowledged'
  },
  acostaReportFieldEnums: {
    SerialNumber: 'assetSerialNumber',
    MarketName: 'market',
  },
  acostaReportStatusDateEnum: {
    StatusDate: 'StatusDate'
  },
  acostaReportFilterOperators: {
    after: 'after',
    before: 'before'
  },
  emptyIsAnyOfOperatorFilters: ["isEmpty", "isNotEmpty", "isAnyOf"],
  filterFieldDataTypes: {
    Number: 'number',
    String: 'string',
    Boolean: 'boolean'
  },
  routesWithNoChildRoute: ['/Installations', '/UserLogin', '/MasterData', '/InFieldStatus', "/Outlet", "/DeviceTracking", "/Data", "/ImportLogs", "/OutletActions", "/MyKPIConfigurator"],
  orderStatusIdEnum: {
    Inbox: { value: 0, statusText: 'Inbox', statusColor: 'primary' },
    Schedule: { value: 5485, statusText: 'Schedule', statusColor: 'warning' },
    InTransit: { value: 5484, statusText: 'In Transit', statusColor: 'success' },
    Ignored: { value: 5486, statusText: 'Ignored', statusColor: 'error' }
  },
  initialImageLabelSequence: 1000,
  questionnaireDefaultSectionItems: [
    {
      "id": 1,
      "SerialNumber": "",
      "AssetType": "",
      "Location": "",
      "RowNumber": 1
    }
  ],
  defaultSurveyTypeCategory: 4219,
  defaultSurveyTypeId: 6507,
  inFieldStatusEnums: {
    Total: 'Total',
    NoCameraAssigned: 'Is Not Smart',
    CameraAssigned: 'Is Smart',
    WorkingHardware: 'Working Hardware',
    NotWorkingHardware: 'Not Working Hardware',
    HardwareInstalledKey: 'HardwareInstalled',
    NotWorkingHardwarekey: 'NotWorkingHardware',
    HardwareInstalledIndex: 2,
    WorkingHardwareIndex: 3
  },
  GridOperators: {
    IsAnyOf: 'isAnyOf'
  },
  OrderSuggestionHistoryFields: {
    OrderStatus: 'OrderStatusId'
  },
  QUESTION_TYPES: {
    RADIO: "radio",
    CHECKBOX: "checkbox",
    TEXT: "text",
    TEXTAREA: "textarea",
    SELECT: "select",
    FILE: "file",
    MULTI_FILE: "multiFile",
    NUMBER: "number",
    BARCODE: "barcode",
    SCENE: "scene",
    MULTI_FILE_STITCH: "multiFileStitch",
    DATE: "date",
  },
  CREATOR_QUESTION_DISPLAYNAME: {
    BOOLEAN: "boolean",
    RADIO: "radio",
    DROPDOWN: "Dropdown",
    CHECKBOX: "checkbox",
    NUMBER: "number",
    DATE: "date",
    SINGLE_LINE_INPUT: "Text Input (Single line)",
    MULTI_LINE_INPUT: "Text Input (Multiple lines)",
    GENERAL_CAPTURE: "General Image Capture",
    BARCODE: "Asset Barcode Capture",
    PRODUCT_CAPTURE: "Product Image Capture",
    POS_CAPTURE: "POS materials image capture"
  },
  SECTION_TITLE_DELIMITER: "-",
  defaultSubmissionsOption: 'user',
  defaultSubmissionsOrder: 'top20',
  defaultCoverageOption: 'City',
  progressLowColor: 'red',
  progressMediumColor: 'orange',
  progressHighColor: 'yellow',
  progressFullColor: 'green',
  operationalDashboardTitle: 'Operational Dashboard',
  operationalDashboardDescription: 'Visualize how users and outlets are being surveyed',
  operationalDefaultPeriod: '30:day',
  defaultDateFormat: 'MM/dd/yyyy',
  customDateLookUpId: '-1',
  fromDateString: 'fromDate',
  toDateString: 'toDate',
  productIdTag: '~${item.ProductId}',
  assetIdTag: '~${item.AssetId}',
  analysisText: 'analysis',
  imagesText: 'images',
  noAnalyticsText: 'No Analytics Data',
  maxCharactersPerLine: 14,
  repeatCountSeparator: '~',
  genSection: 'General Section',
  maximumQuantityToOrder: 99,
  inboxAndSalesChartsModule: 'InboxAndSalesCharts',
  smartDevicesType: {
    SmartHub: 2
  },
  defaultDynamicItems: 'Products',
  dynamicProductsInAsset: 'asset_products',
  temperatureUnit: {
    Celsius: 0,
    Fahrenheit: 1
  },
  surveyInboxPageType: 'SurveyReport',
  frameworkScreens: ['/Installations'],
  portugalFranceClientIds: [390, 385],
  portugalFranceClientIdsWithLanguages: {
    390: 'pt-PT',
    385: 'fr-FR'
  },
  surveyInboxTitle: 'Survey Inbox Overview',
  freshChatScreens: ['login', 'forgot-password'],
  groupByMappings: {
    MarketId: 'MarketName',
    City: 'City',
    PrimarySalesRep: 'PrimarySalesRep',
    State: 'State',
    DistributorId: 'DistributorName',
    LocationTypeId: 'LocationTypeName',
    PreSellerRouteId: 'PreSellerRouteName',
    SerialNumber: 'SerialNumber'
  },
  PreSellerRouteId: 'PreSellerRouteId'
};
constants.SERIAL_NUMBER_TEXT = `${constants.SECTION_TITLE_DELIMITER} Serial Number \${SerialNumber}`;
constants.MODEL_TYPE_TEXT = `${constants.SECTION_TITLE_DELIMITER} Model Type \${AssetType}`;
constants.PRODUCT_NAME_TEXT = `${constants.SECTION_TITLE_DELIMITER} \${Product}`;
export default constants;
