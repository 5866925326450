import React from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    root: { '& .MuiInputBase-input': { textAlign: 'center' } }
});
const SuffixTextField = ({ value, onChange, suffix, inputProps, ...rest }) => {
    const classes = useStyles();

    const handleChange = (e) => {
        const newValue = e.target.value.replace(/[^0-9]/g, '');
        onChange(newValue);
    };

    const displayValue = value ? `${value}${suffix}` : '';
    const displayPlaceholder = value ? '' : '-';

    return (
        <TextField
            {...rest}
            value={displayValue}
            placeholder={displayPlaceholder}
            onChange={handleChange}
            InputProps={{
                ...inputProps,
                sx: { textAlign: 'center' }
            }}
            className={classes.root}
        />
    );
};

export default SuffixTextField;
