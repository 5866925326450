import React, { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { apis, request } from "../../httpUtil";
import {
    Card, CardContent, Typography,
    Grid, Tabs, Tab, Tooltip, Box, FormControl, InputLabel, Select, MenuItem
} from "@material-ui/core";
import { List, ListItem, ListItemButton, ListItemText, DialogTitle, Dialog } from '@mui/material';
import GridBase from "../GridBase";
import CloseIcon from '@material-ui/icons/Close';
import InfoRounded from '@material-ui/icons/InfoRounded';
import actions from "../../redux/actions";
import dayjs from 'dayjs';
import utils from '../../utils'
import kpiDescription from '../../utils/kpiDescription.json'
import { BarChart, PieChart } from './chartsCommon'
import DialogModal from '../Common/DialogModel';
import CoolerView2 from "../VistaLocationDetailsCardList/CoolerView2";
import { getGridStringOperators } from "@mui/x-data-grid-pro";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import TextForObjects from "../../utils/TextForObjects";
import Button from '@mui/material/Button';
import swal from 'sweetalert';
import { useTranslation } from "react-i18next";
import useMobile from '../../utils/useMobile';
import masterUserModel from "../../pages/master-data-users";
import assetListModel from "../../pages/asset-list";
import productListModel from "../../pages/product-list";
import planogramsListModel from "../../pages/planograms-list";
import assetTypeModel from '../../pages/asset-types-list';
import outletTabModel from "../../pages/outlet-tab";
import ManageData from "./ManageData";

const { dayjsFormatLocal, t, defaultFilter, IS_ACTIVE, BOOLEAN_FIELDS } = utils;
const filterOperators = getGridStringOperators().filter(({ value }) =>
    ['equals'].includes(value),
);
const IsChestOperators = getGridStringOperators().filter(({ value }) =>
    ['equals', "isEmpty", "isNotEmpty"].includes(value),
);


const CustomFilterComponent = React.memo((props) => {
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const { item, applyValue, name, list = [], col } = props;
    const [open, setOpen] = React.useState(false);
    const ref = useRef(null);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    const handleFilterChange = (event) => {
        const { value = [] } = event?.target || {};
        applyValue({ ...item, value: value.length > 0 ? value : null })
    }

    return (
        <Box>
            <FormControl className="select-form-control select-form-product">
                <InputLabel className="h4 product-filter-input" >{t(name, tOpts)}</InputLabel>
                <Select
                    multiple={item.columnField !== col}
                    className="w-100 product-filter-select"
                    ref={ref}
                    value={item?.value || []}
                    onChange={handleFilterChange} >
                    {list.map((v, i) => (<MenuItem spacing={3} key={i} value={v}>{t(v, tOpts)}</MenuItem>))}
                </Select>
                {item?.value?.length > 0 && item.columnField !== col && <Button color='primary' onClick={handleClickOpen} className='select-multiple-button'>...</Button>}
            </FormControl>

            {open && <Dialog onClose={handleClose} open={open}>
                <DialogTitle>{t("Selected Products", tOpts)}</DialogTitle>
                <List sx={{ pt: 0 }} className='pl-2'>
                    {item?.value?.length > 0 && item?.value?.map((product, index) => (
                        <ListItem disableGutters className="p-0">
                            <ListItemButton key={index} className="py-1">
                                <ListItemText primary={product} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Dialog>}
        </Box>
    )
})

const customFilterConfig = {
    "Product": (data) => ([...getGridStringOperators(), {
        label: <TextForObjects text="Select" />,
        value: "IN",
        getApplyFilterFn: (filterItem) => {
            //TODO for Client Side Filtering
            return null
        },
        InputComponent: (props) => {
            return <CustomFilterComponent list={data} name={"products"} {...props} />
        }
    }]),
    "IsForeign": (data) => [{
        label: <TextForObjects text="Select" />,
        value: "=",
        InputComponent: (props) => {
            return <CustomFilterComponent list={["Yes", "No"]} name={"foreign"} col={"IsForeign"} {...props} />
        }
    }],
    "IsActive": (data) => [{
        label: <TextForObjects text="Select" />,
        value: "=",
        InputComponent: (props) => {
            return <CustomFilterComponent list={["Yes", "No"]} name={"active"} col={"IsActive"} {...props} />
        }
    }]
}

let columns = ''

const gridConfig = ({ tOpts, key }) => {
    const columnsData = columns ? columns : {
        productList: [
            { id: "Product", label: "Product", flex: 0, minWidth: 100, valueGetter: (value) => value.row.Product },
            { id: "SKU", label: "SKU", flex: 0, minWidth: 100, valueGetter: (value) => value.row.SKU },
            { id: "ProductImageCount", label: "# of Images", flex: 0, minWidth: 120, valueGetter: (value) => value.row.ProductImageCount },
            { id: "ProductCategory", label: "Product Category", flex: 0, minWidth: 100, valueGetter: (value) => value.row.ProductCategory },
            { id: "BrandName", label: "Product Brand", flex: 0, minWidth: 100, valueGetter: (value) => value.row.BrandName },
            { id: "UPC", label: "UPC Number", flex: 0, minWidth: 100, valueGetter: (value) => value.row.UPC },
            { id: "ShortEANCode", label: "EAN Number", flex: 0, minWidth: 100, valueGetter: (value) => value.row.ShortEANCode },
            { id: "PackagingType", label: "Packaging", flex: 0, minWidth: 100, valueGetter: (value) => value.row.PackagingType },
            {
                id: "IsForeign", label: "Foreign", filterOperators, flex: 0, minWidth: 100, valueGetter: (value) => value.row.IsForeign, format: (item) => {
                    return item.value ? 'Yes' : 'No';
                }
            },
        ],
        assetList: [
            { id: "SerialNumber", label: "Asset Serial Number", flex: 0, minWidth: 150, valueGetter: ({ row }) => row.SerialNumber },
            { id: "AssetType", label: "Asset Type", flex: 0, minWidth: 150, valueGetter: ({ row }) => row.AssetType },
            { id: "LocationCode", label: "Outlet Code", flex: 0, minWidth: 150, valueGetter: ({ row }) => row.LocationCode },
            { id: "MDMSerialNumber", label: "Modem", flex: 0, minWidth: 150, valueGetter: ({ row }) => row.MDMSerialNumber },
            { id: "SmartDeviceSerialNumber", label: "Camera", flex: 0, minWidth: 150, valueGetter: ({ row }) => row.SmartDeviceSerialNumber },
            { id: "PlanogramName", label: "Planogram", flex: 0, minWidth: 150, valueGetter: ({ row }) => row.PlanogramName }
        ],
        planogramList: [
            { id: "PlanogramName", label: "Planogram Name", flex: 0, minWidth: 100, valueGetter: (value) => value.row.PlanogramName },
            { id: "AssignedAssetsCount", type: "number", label: "Assigned Assets", flex: 0, minWidth: 150, valueGetter: (value) => value.row.AssignedAssetsCount },
            { id: "Shelves", label: "Shelves", type: "number", flex: 0, minWidth: 150, valueGetter: (value) => value.row.Shelves },
            { id: "Facings", label: "Total SKUs", type: "number", flex: 0, minWidth: 150, valueGetter: (value) => value.row.Facings },
            { id: "FacingsDistinct", label: "Unique SKUs", type: "number", flex: 0, minWidth: 150, valueGetter: (value) => value.row.FacingsDistinct },
            {
                id: "CreatedOn", type: "date", label: "Created On", flex: 0, minWidth: 100, valueGetter: (value) => value.row.CreatedOn, format: (item) => {
                    return utils.formatDate(item.value, true);
                }
            },
            { id: "FacingDetails", label: "Products", minWidth: 150, flex: 0 }
        ],
        outletList: [
            { id: "Name", label: "Name", flex: 0, minWidth: 70, valueGetter: (value) => value.row.Name },
            { id: "Code", label: "Outlet Code", flex: 0, minWidth: 100, valueGetter: (value) => value.row.Code },
            { id: "Country", label: "Country", flex: 0, minWidth: 80, valueGetter: (value) => value.row.Country },
            { id: "State", label: "State", flex: 0, minWidth: 60, valueGetter: (value) => value.row.State },
            { id: "City", label: "City", flex: 0, minWidth: 50, valueGetter: (value) => value.row.City },
            { id: "Street", label: "Street", flex: 0, minWidth: 60, valueGetter: (value) => value.row.Street },
            { id: "PostalCode", label: "Postal Code", flex: 0, minWidth: 100, valueGetter: (value) => value.row.PostalCode },
            { id: "MarketName", label: "Market", flex: 0, minWidth: 70, valueGetter: (value) => value.row.MarketName },
            { id: "LocationType", label: "Channel", flex: 0, minWidth: 80, valueGetter: (value) => value.row.LocationType },
            { id: "Classification", label: "Classification", flex: 0, minWidth: 100, valueGetter: (value) => value.row.Classification },
            { id: "Latitude", label: "Latitude", type: "number", flex: 0, minWidth: 80, valueGetter: (value) => value.row.Latitude ? value.row.Latitude : "" },
            { id: "Longitude", label: "Longitude", type: "number", flex: 0, minWidth: 90, valueGetter: (value) => value.row.Longitude ? value.row.Longitude : "" },
            { id: "TimeZone", label: "Time Zone", flex: 0, minWidth: 90, valueGetter: (value) => value.row.TimeZone },
            { id: "DistributorName", label: "Distributor", flex: 0, minWidth: 90, valueGetter: (value) => value.row.DistributorName },
            { id: "PrimarySalesRep", label: "Sales Rep", flex: 0, minWidth: 90, valueGetter: (value) => value.row.PrimarySalesRep },
            {
                id: "IsActive", type: "string", label: "Active", filterOperators, flex: 0, minWidth: 60, valueGetter: (value) => value.row.IsActive, format: (item) => {
                    return item.value ? 'Yes' : 'No';
                }
            }
        ],
        planogramAsset: [
            { id: "SerialNumber", label: "Asset Serial Number", flex: 0, minWidth: 200, filterable: false, sortable: false },
            { id: "Code", label: "Outlet Code", flex: 1, minWidth: 150, filterable: false, sortable: false }
        ],
        users: [
            { id: "FirstName", label: "First Name", flex: 0, minWidth: 200, valueGetter: (value) => value.row.FirstName },
            { id: "LastName", label: "Last Name", flex: 0, minWidth: 200, valueGetter: (value) => value.row.LastName },
            { id: "PrimaryEmail", label: "Email", flex: 0, minWidth: 200, valueGetter: (value) => value.row.PrimaryEmail },
            { id: "PrimaryPhone", label: "Phone", flex: 0, minWidth: 150, valueGetter: (value) => value.row.PrimaryPhone },
            { id: "Role", label: "Role", flex: 0, minWidth: 150, valueGetter: (value) => value.row.Role },
        ],
        assetType: [
            { id: "AssetType", label: "Asset Type", flex: 0, minWidth: 150, valueGetter: (value) => value.row.AssetType },
            { id: "ModelNumber", label: "Model Number", flex: 0, minWidth: 150, valueGetter: (value) => value.row.ModelNumber },
            { id: "Manufacturer", label: "Manufacturer", flex: 0, minWidth: 150, valueGetter: (value) => value.row.Manufacturer },
            {
                id: "IsChestCooler", label: "Horizontal/Vertical", flex: 0, minWidth: 150, filterOperators: IsChestOperators, valueGetter: (value) => value.row.IsChestCooler, format: (item) => {
                    const val = item.value;
                    if (val === null) {
                        return ""
                    }
                    return val ? 'Horizontal' : 'Vertical';
                }
            },
            { id: "Shelves", label: "Number of Shelves", type: "number", flex: 0, minWidth: 150, valueGetter: (value) => value.row.Shelves },
            { id: "Columns", label: "Number of Columns", type: "number", flex: 0, minWidth: 150, valueGetter: (value) => value.row.Columns },
        ]
    }
    if (key) {
        return columnsData[key]
    } else {
        return columnsData
    }
}

const tabList = [
    { icon: "outlet.png", iconSize: '30', iconPosition: "start", label: "Outlets" },
    { icon: "assetType.png", iconSize: '30', iconPosition: "start", label: "Asset Types" },
    { icon: "asset.png", iconSize: '30', iconPosition: "start", label: "Assets" },
    { icon: "planogram-icon.png", iconSize: '30', iconPosition: "start", label: "Planograms" },
    { icon: "product.png", iconSize: '30', iconPosition: "start", label: "Products" },
    { icon: "users.png", iconSize: '30', iconPosition: "start", label: "Users" }
]

const productGridFilterOptions = { operator: "equals" };
const filterKeysMapping = {
    "Market": "MarketName",
    "OutletCode": "Code",
    "LocationCode": "Code",
    "Channel": "LocationType",
    "Active": "IsActive",
    "AssetSerialNumber": "SerialNumber",
    "Modem": "MDMSerialNumber",
    "Camera": "SmartDeviceSerialNumber",
    "Planogram": "PlanogramName"
}
const operatorMappings = {
    "IsActive": "equals"
}

function MasterDataDashboard() {

    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const isMobile = useMobile();
    const history = useHistory();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = React.useState(0);
    const [productData, setProductData] = useState({})
    const [outletData, setOutletData] = useState({})
    const [assetData, setAssetData] = useState({})
    const [planogramData, setPlanogramData] = useState({})
    const [assetTypeData, setAssetTypeData] = useState({})
    const [usersData, setUsersData] = useState({})
    const [sectionToPrint, setSectionToPrint] = useState('section-to-print-1')
    const [productDetails, setProductDetails] = useState({ status: false, heading: "", data: [] });
    const [planogramDetails, setPlanogramDetails] = useState({ status: false, heading: "", data: {} });
    const [planogramAssetData, setPlanogramAssetData] = useState({ status: false, data: {} });
    const [customFilterData, setCustomFilterData] = useState({});
    const [filterModel, setFilterModel] = useState({ items: [] })
    const [exportColumns, setExportColumns] = useState([]);
    const [filter, setFilters] = useState(defaultFilter);
    const [hideAllColumns, setHideAllColumns] = useState(false)
    const [oldFilter, setOldFilters] = useState({ ...defaultFilter, linkOperator: "AND" });
    const [filterReady, setFilterReady] = useState(false);
    const [Product, setProduct] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [gridColumns] = useState({
        planogramList: gridConfig({ tOpts, key: "planogramList" })
    })
    const onFilterModelChange = utils.onFilterModelChange({ setFilters });
    const [currentChartFilter, setCurrentChartFilter] = useState({ key: '', operator: '' });
    const page = filter?.pagination?.page || 0;
    const pageSize = filter?.pagination.pageSize;
    let importUrl;
    let exportUrl;
    let params;
    let exportNote;
    let disable;
    const commonGridOptions = {
        filterMode: 'server',
        sortingMode: 'server',
        filterModel: filterModel,
        page: page,
        paginationMode: 'server',
        onPageChange: setPage,
        pageSize: pageSize,
        onPageSizeChange: setPageSize,
        onFilterModelChange: setFilterModel,
        getCellClassName: (params) => {
            if ((!params.value) || (params.value === '(Blank)')) {
                return 'grid-bg-red'
            }
        },
        disableSelectionOnClick: true,
        density: "compact",
        hideFooter: false,
    }

    useEffect(() => {
        dispatch({
            type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
                filterButton: null,
                hidden: { search: true, operation: true, export: true, print: false, filter: true }
            }
        });
        window.addEventListener('resize', productTableHeaderAdjust);

        return () => {
            window.removeEventListener('resize', productTableHeaderAdjust);
            dispatch({
                type: actions.PASS_FILTERS_TOHEADER, filtersInHeader: {
                    filterButton: null,
                    clear: null,
                    apply: null,
                    hidden: { search: false, operation: false, export: false, print: false, filter: false }
                }
            });
        }
    }, []);

    useEffect(() => {
        getMasterData({})
    }, [activeTab])

    useEffect(() => {
        const pagination = JSON.stringify(filter.pagination) === JSON.stringify(oldFilter.pagination),
            sort = JSON.stringify(filter.sort) === JSON.stringify(oldFilter.sort),
            where = JSON.stringify(filter.where) === JSON.stringify(oldFilter.where),
            operator = JSON.stringify(filter.linkOperator) === JSON.stringify(oldFilter.linkOperator);

        if (filter.isGridFilter && (!pagination || !sort || !where || !operator)) {
            getMasterData({ extra: filter, filter: true });
            setOldFilters(utils.deepCloneObject(filter));
        }
    }, [filter])

    useEffect(() => {
        if (filterReady) {
            onFilterModelChange({ ...filterModel, pagination: { ...(filter?.pagination || {}), page: 0 } })
        }
    }, [filterModel])

    useEffect(() => {
        if (Product?.length) {
            setCustomFilterData(prev => ({ ...prev, Product: Product.map(v => v.DisplayValue) }))
        }
    }, [Product])

    useEffect(() => {
        switch (pageSize) {
            case 10: setSectionToPrint('section-to-print-1'); break;
            case 20: setSectionToPrint('section-to-print-1'); break;
            case 50: setSectionToPrint('section-to-print-2'); break;
            case 100: setSectionToPrint('section-to-print-3'); break;
            default: setSectionToPrint('section-to-print-1')
        }
    }, [pageSize])

    useEffect((v) => {
        columns = gridConfig({ tOpts })
        for (let key in columns) {
            if (key !== "planogramList") {
                for (let [index, column] of columns[key].entries()) {
                    if (!customFilterConfig[column.id]) {
                        continue;
                    }
                    const data = customFilterData[column.id];
                    columns[key][index].filterOperators = customFilterConfig[column.id](data);
                }
            }

        }
    }, [customFilterData])

    function setPage(page) {
        setFilters(prev => ({ ...prev, isGridFilter: true, pagination: { ...prev.pagination, page } }));
    }

    function setPageSize(pageSize) {
        setFilters(prev => ({ ...prev, isGridFilter: true, pagination: { ...prev.pagination, pageSize, page: 0 } }));
    }

    const handleChangeTab = (event, newValue) => {
        if (activeTab !== newValue) {
            setFilterModel({ items: [] });
            setHideAllColumns(false)
            setFilters(defaultFilter);
            setOldFilters({ ...defaultFilter, linkOperator: "AND" })
            setFilterReady(false)
            setFilterReady(false)
            setActiveTab(newValue);
            setExportColumns([])
        }
    };

    async function getMasterData({ action = "", extra = {}, filter: isGridFilter = false }) {
        const { page, pageSize } = filter?.pagination || {};
        let selectedAction;
        switch (activeTab) {
            case 0: selectedAction = `Outlets`; break;
            case 1: selectedAction = "AssetType"; break;
            case 2: selectedAction = "Assets"; break;
            case 3: selectedAction = "Planograms"; break;
            case 4: selectedAction = "Products"; break;
            case 5: selectedAction = "Users"; break;
            default: selectedAction = ''
        }
        const payload = {
            ...extra,
            action: selectedAction,
            pagination: {
                page: page + 1,
                pageSize
            },
        };
        const additionalParams = {}
        if (action === 'export') {
            payload["exportData"] = true;
            payload["exportColumns"] = exportColumns
        }
        const data = await request({ url: apis.MasterDataDash, params: payload, history, dispatch, jsonPayload: true, additionalParams });
        const newData = isGridFilter ? data.data : data
        if (newData) {
            switch (activeTab) {
                case 0: {
                    const chartData = [];
                    for (const key in newData.outletNullPer) {
                        chartData.push({ label: key, value: newData.outletNullPer[key] })
                    }
                    if (isGridFilter) {
                        setOutletData(prev => ({ ...prev, ...newData }))
                        return;
                    }
                    setOutletData({ ...newData, chartData: chartData })
                    break;
                }
                case 1: {
                    setAssetTypeData(newData);
                    break;
                }
                case 2: {
                    const chartData = [];
                    for (const key in newData.outletNullPer) {
                        chartData.push({ label: key, value: newData.outletNullPer[key] })
                    }
                    if (isGridFilter) {
                        setAssetData(prev => ({ ...prev, ...newData }))
                        return;
                    }
                    setAssetData({ ...newData, chartData: chartData });
                    break;
                }
                case 3: {
                    let lessThan5Sum = 0;
                    const selectedPlanograms = [], updatedData = { ...planogramData, ...newData };
                    if (updatedData?.planogramSelected) {
                        for (const product of updatedData?.planogramSelected) {
                            const percentage = Number(product.AssetPercentage);
                            if (percentage < 5) {
                                lessThan5Sum += percentage;
                            } else {
                                product.AssetPercentage = percentage.toFixed(2);
                                selectedPlanograms.push(product);
                            }
                        }
                    }
                    if (lessThan5Sum > 0) {
                        selectedPlanograms.push({ AssetPercentage: lessThan5Sum.toFixed(2), PlanogramName: 'Others' });
                    }

                    updatedData.planogramSelected = selectedPlanograms;
                    updatedData.productPresence = updatedData.productPresence?.length ? updatedData.productPresence.sort(function (a, b) { return b.ProductPercentage - a.ProductPercentage }) : [];
                    setPlanogramData(updatedData);
                    break;
                }
                case 4: {
                    setProductData(isGridFilter ? newData : newData.data);
                    break;
                }
                case 5: {
                    setUsersData(newData);
                    break;
                }
                default: { }
            }
        }
        !filterReady && setFilterReady(true)
    }

    function exportExcel(action) {
        if (exportColumns.length === 0 && hideAllColumns) {
            swal({
                title: '',
                text: t("You cannot export while all columns are hidden... please show at least 1 column before exporting", tOpts),
                icon: "warning",
                dangerMode: false
            });
        } else {
            getMasterData({
                action: "export",
                extra: {
                    "responseType": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "fileName": action.replace(/ /g, ""),
                    ...filter
                }
            })
        }

    }

    const handleExportColumnsChnage = (columns) => {
        const columnsCloned = columns;
        const newColumns = [];
        Object.keys(columnsCloned).forEach(key => {
            if (columnsCloned[key] !== false) {
                newColumns.push(key);
            }
        });
        setExportColumns(newColumns);
        setHideAllColumns(true)
    }

    function filterByEmpty(page) {
        const operatorValue = "isEmpty"
        const data = columns[page] || [];
        const items = []
        for (let item of data) {
            if (BOOLEAN_FIELDS.includes(item.id)) {
                items.push({
                    columnField: item.id,
                    value: "no",
                    operatorValue: operatorMappings[item.id]

                })
                continue;
            }
            items.push({
                columnField: item.id,
                value: null,
                operatorValue: operatorValue

            })
        }
        setFilterModel({
            pagination: {
                ...filter?.pagination,
                page: 0
            },
            linkOperator: "or",
            items
        })
    }

    function onChartElementClick({ value = '', key, operator, product }) {
        if (!(currentChartFilter.key === key && currentChartFilter.operator === operator) || product) {
            let { operatorValue = "equals" } = productGridFilterOptions;
            if (!value && value !== 0) {
                operatorValue = "isEmpty"
            };
            value = (value !== null && value !== undefined) ? value : null;
            setFilterReady(true)
            setFilterModel({
                pagination: {
                    ...filter?.pagination,
                    page: 0
                },
                items: [{
                    columnField: key,
                    value: key === "ProductImageCount" ? value.toString() : value,
                    operatorValue: operator || operatorValue
                }]
            })
            setCurrentChartFilter({ key, operator });
        }
    }

    function clearFilter() {
        setFilterModel({
            pagination: { ...filter?.pagination, page: 0 },
            linkOperator: "or",
            items: []
        })
        setCurrentChartFilter({ key: '', operator: '' });
    }

    function filterAllIssues(callback) {
        return <Button variant="text" onClick={callback} className='grid-toolbar-button'><ErrorOutlineIcon className='grid-toolbar-icon' />{t('Filter All Issues', tOpts)}</Button>
    }

    // Product tab component
    const productComponent = () => {
        importUrl = `${apis.ImportProduct}`;
        exportUrl = `${apis.Product}`;
        exportNote = 'Click on export button to export product list';
        disable = false;
        params = {
            action: "export",
            asArray: 1,
            sort: "Product",
            dir: "ASC",
            exportFileName: "Products",
            exportFormat: "XLSX"
        }
        function formatCellColor(params) {
            if (params.field === "ProductImageCount") {
                return params.value === 0 ? 'grid-bg-red' :
                    params.value === 1 ? 'grid-bg-orange' : 'grid-bg-green'
            } else if (params.field === "ProductCategory" || params.field === "BrandName") {
                if (!params.value) {
                    return 'grid-bg-red'
                }
            }
        }

        return <>
            {Object.keys(productData).length > 0 ?
                <Grid container className="grid_wrapper">
                    <Grid item md={4} sm={4} lg={4} className="lg_grid_item">
                        <div className="d-flex">
                            <Typography variant="h5">{t('Product Image', tOpts)}</Typography>
                            <Tooltip title={t(kpiDescription.mdImages, tOpts)} placement="right">
                                <span className="ml-2"><InfoRounded style={{ height: "15px" }} /></span>
                            </Tooltip>
                        </div>
                        <div className="md-chart-container-outer">
                            <BarChart key="product-bar-chart" data={productData?.ProductImageCount || []} config={{
                                labelName: "ProductImageCount",
                                dataName: "Products",
                                chartName: t('Product Image', tOpts),
                                gridFilterKey: 'ProductImageCount',
                                xAxisName: t("# of Images", tOpts),
                                yAxisName: t("Products", tOpts),
                                tab: "product",
                                chartContainerClass: "md-chart-container-inner",
                                fontSize: 14,
                                toolTipToggle: false,
                                defaultToggle: "onlyValuesBar",
                                gridLines: { x: false, y: false },
                                legend: 'hidden'
                            }} />
                        </div>
                    </Grid>
                    <Grid item md={4} sm={4} lg={4} className="pl-3 md_grid_item">
                        <div className="d-flex">
                            <Typography variant="h5">{t('Product Category', tOpts)}</Typography>
                            <Tooltip title={t(kpiDescription.mdCategory, tOpts)} placement="right">
                                <span className="ml-2"><InfoRounded style={{ height: "15px" }} /></span>
                            </Tooltip>
                        </div>
                        <div className="md-chart-container-outer">
                            <PieChart key="category-pie-chart" data={productData?.ProductCategory || []} config={{
                                labelName: "ProductCategory",
                                dataName: "Products",
                                chartName: t('Product Category', tOpts),
                                chartContainerClass: "md-chart-container-inner",
                                fontSize: 14,
                                gridFilterKey: 'ProductCategory',
                                toolTipToggle: false,
                                defaultToggle: "toolTipAndValuesPie",
                            }} />
                        </div>
                    </Grid>
                    <Grid item md={4} sm={4} lg={4} className="pl-3 md_grid_item">
                        <div className="d-flex">
                            <Typography variant="h5">{t('Product Brand', tOpts)}</Typography>
                            <Tooltip title={t(kpiDescription.mdBrand, tOpts)} placement="right">
                                <span className="ml-2"><InfoRounded style={{ height: "15px" }} /></span>
                            </Tooltip>
                        </div>
                        <div className="md-chart-container-outer">
                            <PieChart key="brand-pie-chart" data={productData?.BrandName || []} config={{
                                labelName: "BrandName",
                                dataName: "Products",
                                chartName: "Product Brands",
                                chartContainerClass: "md-chart-container-inner",
                                fontSize: 14,
                                toolTipToggle: false,
                                gridFilterKey: 'BrandName',
                                defaultToggle: "toolTipAndValuesPie",
                            }} />
                        </div>
                    </Grid>

                    <Grid item md={12} sm={12} lg={12} className="gridbase-wraper">
                        <Card>
                            <CardContent>
                                <Grid container >
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div className="d-flex d-flex-space-between d-flex-align-center">
                                            <div className="d-flex">
                                                <Typography variant="h5">{productDetails.status ? t(productDetails.heading, tOpts) : t('Product List', tOpts)}</Typography>
                                                <Tooltip title={t(kpiDescription.dataGrid, tOpts)} placement="right">
                                                    <span className="ml-2"><InfoRounded style={{ height: "15px" }} /></span>
                                                </Tooltip>
                                            </div>
                                            <Grid>
                                                <img className="ml-4" onClick={() => setOpenModal(true)} src={require(`../../assets/images/data-management.png`)} height='35' alt={"Export"} />
                                                <Typography variant="h6" className="mr-4">{t("Manage Data", tOpts)}</Typography>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={2} lg={2} className="d-flex justify-content-end" >
                                        <div className="masterData-export-Container">
                                            {productDetails.status && <CloseIcon onClick={() => { setProductDetails({ status: false, data: [], heading: '' }) }} className="icon-search cursor_pointer" />}
                                        </div>
                                    </Grid>
                                    <div style={{ width: '100%' }}>
                                        <productListModel.Grid importUrl={importUrl} exportUrl={exportUrl} action={"Product"} exportNote={exportNote} disable={disable} openModal={openModal} setOpenModal={setOpenModal} manageDataName={'Product'} />
                                    </div>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid> :
                <p>{t('No Data', tOpts)}</p>}
        </>
    }

    const productTableHeaderAdjust = () => {
        const headerPI = document.getElementById("mdProductImageHeader");
        let piHeight = null;
        if (headerPI) {
            piHeight = headerPI.offsetHeight;
            const headerPC = document.getElementById("mdProductCategoryHeader");
            const headerPB = document.getElementById("mdProductBrandHeader");
            if (headerPC)
                headerPC.style.height = piHeight + "px";
            if (headerPB)
                headerPB.style.height = piHeight + "px";
        }
    }

    function outletCustomGetFunc({ chart, element }) {
        let operator = 'isEmpty', value = '';
        const { labelWithoutTranlsation = '', data: [] } = chart.data.datasets[element._datasetIndex] || {};
        let key = chart.data.keyData[element._index] || '';
        key = key.replaceAll(/\s/g, '');
        key = filterKeysMapping[key] || key;
        operator = labelWithoutTranlsation.includes("empty") ? operator : 'isNotEmpty';
        if (operatorMappings[key]) {
            operator = operatorMappings[key];
        }
        if (key === IS_ACTIVE) {
            value = labelWithoutTranlsation.includes("empty") ? false : true;
        }
        return { key, operator, value }
    }

    // Outlet tab component
    const outletComponent = () => {
        importUrl = `${apis.ImportOutlet}`;
        exportUrl = `${apis.Location}`;
        exportNote = 'Click on export button to export outlet list';
        disable = false;
        params = {
            action: "export",
            asArray: 1,
            sort: "PrimarySalesRep",
            dir: "ASC",
            exportFileName: "Outlet",
            exportFormat: "XLSX"
        }
        return <>
            {Object.keys(outletData).length > 0 ?
                <Grid container>
                    <Grid item md={12} sm={12} lg={12} className="">
                        <div className="d-flex">
                            <Typography variant="h5">{t("Outlets", tOpts)}</Typography>
                            <Tooltip title={t(kpiDescription.barChartNull, tOpts)} placement="right">
                                <span className="ml-2"><InfoRounded style={{ height: "15px" }} /></span>
                            </Tooltip>
                        </div>
                        <div className="md-chart-container-outer">
                            <BarChart key="outlet-bar-chart" data={outletData?.chartData || []} config={{
                                labelName: 'label',
                                dataName: "value",
                                chartName: t("Outlet", tOpts),
                                xAxisName: "",
                                yAxisName: "",
                                tab: "outlet",
                                unit: "%",
                                chartContainerClass: "md-chart-container-inner",
                                useDataSet: true,
                                fontSize: 14,
                                toolTipToggle: false,
                                defaultToggle: "showAllBar",
                                gridLines: { x: false, y: false },
                                labelMap: gridConfig({ tOpts, key: "outletList" })
                            }} />
                        </div>
                    </Grid>
                    <Grid item md={12} sm={12} lg={12} className="mt-2 gridbase-wraper">
                        <Card>
                            <CardContent>
                                <Grid container >
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div className="d-flex d-flex-space-between d-flex-align-center">
                                            <div className="d-flex">
                                                <Typography variant="h5">{t("Outlet List", tOpts)}</Typography>
                                                <Tooltip title={t(kpiDescription.dataGrid, tOpts)} placement="right">
                                                    <span className="ml-2"><InfoRounded style={{ height: "15px" }} /></span>
                                                </Tooltip>
                                            </div>
                                            <div>
                                                <img className="ml-4" onClick={() => setOpenModal(true)} src={require(`../../assets/images/data-management.png`)} height='35' alt={"Export"} />
                                                <Typography variant="h6" className="mr-4">{t("Manage Data", tOpts)}</Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                    <div className="w-100">
                                        <outletTabModel.Grid importUrl={importUrl} exportUrl={exportUrl} action={"Outlet"} exportNote={exportNote} disable={disable} openModal={openModal} setOpenModal={setOpenModal} manageDataName={'Outlet'}/>
                                    </div>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid> :
                <p>{t("No Data", tOpts)}</p>}
        </>
    }
   
    // Asset tab component
    const assetComponent = () => {
        importUrl = `${apis.ImportAsset}`;
        exportUrl = `${apis.Asset}`;
        exportNote = 'Click on export button to export asset list';
        disable = false;
        params = {
            action: "export",
            asArray: 1,
            sort: "SerialNumber",
            dir: "ASC",
            exportFileName: "Assets",
            exportFormat: "XLSX",
            Title: "Assets"
        }
        return <>
            {Object.keys(assetData).length > 0 ?
                <Grid container>
                    <Grid item md={12} sm={12} lg={12} className="">
                        <div className="d-flex">
                            <Typography variant="h5">{t('Assets', tOpts)}</Typography>
                            <Tooltip title={t(kpiDescription.barChartNull, tOpts)} placement="right">
                                <span className="ml-2"><InfoRounded style={{ height: "15px" }} /></span>
                            </Tooltip>
                        </div>
                        <div className="md-chart-container-outer">
                            <BarChart key="outlet-bar-chart" data={assetData?.chartData || []} config={{
                                labelName: t("label", tOpts),
                                dataName: "value",
                                chartName: t('Asset', tOpts),
                                xAxisName: "",
                                yAxisName: "",
                                tab: "outlet",
                                unit: "%",
                                chartContainerClass: "md-chart-container-inner",
                                onElementClick: onChartElementClick,
                                useDataSet: true,
                                customGetValue: outletCustomGetFunc,
                                fontSize: 14,
                                toolTipToggle: false,
                                defaultToggle: "showAllBar",
                                gridLines: { x: false, y: false },
                                labelMap: gridConfig({ tOpts, key: "assetList" })
                            }} />
                        </div>
                    </Grid>
                    <Grid item md={12} sm={12} lg={12} className="mt-2 gridbase-wraper">
                        <Card>
                            <CardContent>
                                <Grid container >
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div className="d-flex d-flex-space-between d-flex-align-center">
                                            <div className="d-flex">
                                                <Typography variant="h5">{t('Asset List', tOpts)}</Typography>
                                                <Tooltip title={t(kpiDescription.dataGrid, tOpts)} placement="right">
                                                    <span className="ml-2"><InfoRounded style={{ height: "15px" }} /></span>
                                                </Tooltip>
                                            </div>
                                            <div>
                                                <img className="ml-4" onClick={() => setOpenModal(true)} src={require(`../../assets/images/data-management.png`)} height='35' alt={"Export"} />
                                                <Typography variant="h6" className="mr-4">{t("Manage Data", tOpts)}</Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                    <div style={{ width: '100%' }}>
                                        <assetListModel.Grid clearChartFilter={clearFilter} chartFilters={filterModel} setChartFilter={setFilterModel} showFullScreenLoader={true} importUrl={importUrl} exportUrl={exportUrl} action={"Asset"} exportNote={exportNote} disable={disable} openModal={openModal} setOpenModal={setOpenModal} manageDataName={'Asset'}></assetListModel.Grid>
                                    </div>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid> :
                <p>{t('No Data', tOpts)}</p>
            }
        </>
    }

    // Planograms tab component
    const planogramComponent = () => {
        importUrl = apis.ImportPlanogram;
        exportUrl = `${apis.PlanogramAsset}`;
        exportNote = 'Click on export button to export an empty template';

        async function openPlanogram(event) {
            const row = event?.row;
            const payload = { action: "previewPlanogram", otherAction: "previewPlanogram", PlanogramId: row.PlanogramId, AssetId: "" }
            const data = await request({ url: apis.AssetPurityList, params: payload, history, dispatch });
            if (data) {
                const planogramTableData = await request({ url: apis.PlanogramAsset, params: { PlanogramId: row.PlanogramId }, history, dispatch, jsonPayload: true });
                if (planogramTableData) {
                    setPlanogramAssetData({ status: true, data: planogramTableData })
                }
                dispatch({ type: 'SET_PLANOGRAM_CHECKBOX', isPlanogram: { key: 0, value: true } })
                dispatch({ type: 'SET_INSTORE_CHECKBOX', isInStore: { key: 0, value: false } })
                setPlanogramDetails({ status: true, heading: row.PlanogramName, data: data.planogram[0] })
            }
        }

        return <>
            {Object.keys(planogramData).length > 0 ?
                <>
                    <Grid container>
                        <Grid item xs={12} sm={12} className="mb-2">
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={6} md={6} lg={6} className="print3-30">
                                    <div className="d-flex">
                                        <Typography variant="h5">{t('Planogram Selected', tOpts)}</Typography>
                                        <Tooltip title={t(kpiDescription.planogramOnAssets, tOpts)} placement="right">
                                            <span className="ml-2"><InfoRounded style={{ height: "15px" }} /></span>
                                        </Tooltip>
                                    </div>
                                    <div className="md-chart-container-outer">
                                        <PieChart key="category-pie-chart" data={planogramData?.planogramSelected || []} config={{
                                            labelName: "PlanogramName",
                                            dataName: "AssetPercentage",
                                            chartName: t('Planogram Selected', tOpts),
                                            chartContainerClass: "md-chart-container-inner",
                                            fontSize: 14,
                                            gridFilterKey: 'PlanogramSelected',
                                            toolTipToggle: false,
                                            defaultToggle: "toolTipAndValuesPie",
                                        }} />
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} className="print3-20">
                                    <Grid container>
                                        <Grid item xs={3} sm={3} md={3} lg={3}></Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} className='text-center'>
                                            <div>
                                                <Typography variant="h5">{t('Planogram Status', tOpts)}</Typography>
                                            </div>
                                            <Card className="planogram-status-card">
                                                <CardContent>
                                                    <div className='pl-2 pr-2'>
                                                        <Typography className="mb-1" variant="h4" align="center">{dayjs(planogramData?.lastCreatedPlanogram).format('DD MMM YYYY') || '--'}</Typography>
                                                        <br />
                                                        <Typography className="mb-1" variant="h7" align="center">{t(kpiDescription?.lastCreatedPlanogramTitle, tOpts)}</Typography>
                                                        <br />
                                                        <Typography className="mb-1" variant="caption" align="center">{t(kpiDescription.lastCreatedPlanogram, tOpts)}</Typography>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                            <Card className="planogram-status-card">
                                                <CardContent>
                                                    <div className='pl-2 pr-2'>
                                                        <Typography className="mb-1" variant="h4" align="center">{planogramData?.averagePlanogram || '--'}</Typography>
                                                        <br />
                                                        <Typography className="mb-1" variant="h7" align="center">{t(kpiDescription?.averagePlanogramTitle, tOpts)}</Typography>
                                                        <br />
                                                        <Typography className="mb-1" variant="caption" align="center">{t(kpiDescription.averagePlanogram, tOpts)}</Typography>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={3} sm={3} md={3} lg={3}></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid >
                        <Grid item md={12} sm={12} lg={12} className="gridbase-wraper">
                            <Card>
                                <CardContent>
                                    <Grid container >
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <div className="d-flex d-flex-space-between d-flex-align-center">
                                                <div className="d-flex">
                                                    <Typography variant="h5">{t('Planogram List', tOpts)}</Typography>
                                                    <Tooltip title={t(kpiDescription.mdPlanogramList, tOpts)} placement="right">
                                                        <span className="ml-2"><InfoRounded style={{ height: "15px" }} /></span>
                                                    </Tooltip>
                                                </div>
                                                <div>
                                                <img className="ml-4" onClick={() => setOpenModal(true)} src={require(`../../assets/images/data-management.png`)} height='35' alt={"Export"} />
                                                    <Typography variant="h6" className="mr-4">{t("Manage Data", tOpts)}</Typography>
                                                </div>
                                            </div>
                                        </Grid>
                                        <div style={{ width: '100%' }}>
                                            <planogramsListModel.Grid onRowDoubleClick={openPlanogram} importUrl={importUrl} exportUrl={exportUrl} action={"Planogram"} exportNote={exportNote} disable={disable} openModal={openModal} setOpenModal={setOpenModal} manageDataName={'Planogram'} />
                                        </div>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid >

                    {planogramDetails.status && // For Prevent Rerendering
                        <DialogModal open={planogramDetails.status} onClose={() => { setPlanogramDetails({ status: false, heading: "", data: {} }) }} dialogTitle={`Planogram - ${planogramDetails.heading}`} maxWidth="md" dividers={true} hideCancel={true} >
                            <div className="planogram-container">
                                <CoolerView2 data={{ Planogram: planogramDetails.data, Facings: planogramDetails.data }} index={0} showSeparator={true} />
                            </div>
                            <div className="planogram-container">
                                <GridBase
                                    customClass={'mt-2 data-grid-font-12'}
                                    columns={gridConfig({ tOpts, key: "planogramAsset" })}
                                    data={planogramAssetData.data}
                                    otherOptions={{ disableSelectionOnClick: true, density: "compact", hideFooter: false }}
                                    pagination={false} />
                            </div>
                        </DialogModal>}
                </> :
                <p>{t('No Data', tOpts)}</p>
            }
        </>
    }

    // users tab component
    const usersComponent = () => {
        importUrl = `${apis.ImportUser}`;
        exportUrl = `${apis.ClientUser}`;
        exportNote = 'Click on export button to export users';
        disable = false;
        params = {
            action: "export",
            asArray: 1,
            sort: "FirstName",
            dir: "ASC",
            exportFileName: "Client Users",
            exportFormat: "XLSX",
            Title: "Client Users"

        }
        return <>
            {Object.keys(usersData).length > 0 ?
                <Grid container>
                    <Grid item md={12} sm={12} lg={12} className="mt-2 gridbase-wraper">
                        <Card>
                            <CardContent>
                                <Grid container >
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div className="d-flex d-flex-space-between d-flex-align-center">
                                            <div className="d-flex">
                                                <Typography variant="h5">{t('Users', tOpts)}</Typography>
                                                <Tooltip title={t(kpiDescription.dataGrid, tOpts)} placement="right">
                                                    <span className="ml-2"><InfoRounded style={{ height: "15px" }} /></span>
                                                </Tooltip>
                                            </div>
                                            <Grid>
                                                <img className="ml-4" onClick={() => setOpenModal(true)} src={require(`../../assets/images/data-management.png`)} height='35' alt={"Export"} />
                                                <Typography variant="h6" className="mr-4">{t("Manage Data", tOpts)}</Typography>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <div style={{ width: '100%' }}>
                                        <masterUserModel.Grid clearChartFilter={clearFilter} chartFilters={filterModel} setChartFilter={setFilterModel} importUrl={importUrl} exportUrl={exportUrl} action={"Users"} exportNote={exportNote} disable={disable} openModal={openModal} setOpenModal={setOpenModal} manageDataName={'Users'}></masterUserModel.Grid>
                                    </div>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid> :
                <p>{t('No Data', tOpts)}</p>
            }
        </>
    }

    // assetType tab component
    const assetTypeComponent = () => {
        importUrl = `${apis.ImportAssetType}`;
        exportUrl = `${apis.AssetType}`;
        exportNote = 'Click on export button to export asset types';
        disable = false;
        params = {
            action: "export",
            asArray: 1,
            sort: "AssetType",
            dir: "ASC",
            exportFileName: "Asset Types",
            exportFormat: "XLSX"
        }

        return <>
            {Object.keys(assetTypeData).length > 0 ?
                <Grid container>
                    <Grid item md={12} sm={12} lg={12} className="mt-2  gridbase-wraper">
                        <Card>
                            <CardContent>
                                <Grid container >
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div className="d-flex d-flex-space-between d-flex-align-center">
                                            <div className="d-flex">
                                                <Typography variant="h5">{t('Asset Types', tOpts)}</Typography>
                                                <Tooltip title={t(kpiDescription.dataGrid, tOpts)} placement="right">
                                                    <span className="ml-2"><InfoRounded style={{ height: "15px" }} /></span>
                                                </Tooltip>
                                            </div>
                                            <Grid>
                                                <img className="ml-4 cursor-pointer" onClick={() => setOpenModal(true)} src={require(`../../assets/images/data-management.png`)} height='35' alt={"Export"} />
                                                <Typography variant="h6" className="mr-4"> {t("Manage Data", tOpts)}  </Typography>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <div style={{ width: '100%' }}>
                                        <assetTypeModel.Grid importUrl={importUrl} exportUrl={exportUrl} action={"Asset Type"} exportNote={exportNote} disable={disable} openModal={openModal} setOpenModal={setOpenModal} manageDataName={'Asset Type'}/>
                                    </div>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid> :
                <p>{t('No Data', tOpts)}</p>
            }
        </>
    }
    const activeProductTab = activeTab === 4 ? "active-tab-print-page-size" : "";
    return <>
        <Card className={`mb-1 p-0  ${!isMobile.mobile ? "mt-2 " : (isMobile.portrait ? "mt-4" : "mt-2")}`} >
            <CardContent className={`m-0 p-0`}>
                <Tabs
                    centered
                    value={activeTab}
                    onChange={handleChangeTab}
                    variant={isMobile.mobile ? "scrollable" : "fullWidth"}
                    scrollButtons={isMobile.mobile ? 'on' : 'off'} >
                    {tabList.map(tab => {
                        return <Tab
                            icon={<img src={require(`../../assets/images/${tab.icon}`)} height={tab.iconSize} alt="" />}
                            iconPosition={tab.iconPosition}
                            label={t(tab.label, tOpts)} />
                    })}
                </Tabs>
            </CardContent>
        </Card>
        <Card className={`mt-2 ${sectionToPrint} ${activeProductTab}`}>
            <CardContent>
                {activeTab === 0 ? outletComponent() :
                    activeTab === 1 ? assetTypeComponent() :
                        activeTab === 2 ? assetComponent() :
                            activeTab === 3 ? planogramComponent() :
                                activeTab === 4 ? productComponent() :
                                    activeTab === 5 ? usersComponent() : ""}
            </CardContent>
        </Card>
    </>
}

export default MasterDataDashboard;