import dayjs from 'dayjs';
import apis from '../httpUtil/apis';
import { transport, HTTP_STATUS_CODES } from '../httpUtil/httpRequest';
import utils from '../utils';
import constants from '../utils/constants';
const dateDataTypes = ['date', 'dateTime'];

const getList = async ({ gridColumns, setIsLoading, setData, page, pageSize, sortModel, filterModel, api, parentFilters, action = 'list', setError, extraParams, contentType, columns, controllerType = 'node', template = null, configFileName = null, dispatch, showFullScreenLoader = false, oderStatusId = 0, history = null, modelConfig = null, baseFilters = null, isElasticExport, fromSelfServe = false, isDetailsExport = false, setFetchData = () => { }, selectedClients = [], isChildGrid = false, groupBy, isPivotGrid }) => {
    if (!contentType) {
        setIsLoading(true);
        if (showFullScreenLoader) {
            dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: true });
        }
    }

    const lookups = [];
    const dateColumns = [];
    gridColumns.forEach(({ lookup, type, field, keepUTC = false }) => {
        if (dateDataTypes.includes(type)) {
            dateColumns.push({ field, keepUTC });
        }
        if (!lookup) {
            return;
        }
        if (!lookups.includes(lookup)) {
            lookups.push(lookup);
        }
    });

    const where = [];
    if (filterModel?.items?.length) {
        const filterFieldName = isPivotGrid ? 'fieldName' : 'field';
        filterModel.items.forEach(filter => {
            if (["isEmpty", "isNotEmpty"].includes(filter.operator) || filter.value || filter.value === 0) {
                const { field, operator, filterField } = filter;
                let { value } = filter;
                const column = gridColumns.filter((item) => item.field === filter.field);
                const type = column[0]?.type;
                if (type === 'boolean') {
                    value = value === 'true' ? 1 : 0;
                } else if (type === 'number') {
                    value = Array.isArray(value) ? value.filter(e => e) : value;
                }
                value = filter.filterValues || value;
                where.push({
                    [filterFieldName]: filterField || field,
                    operator: operator,
                    value: value,
                    type: type
                });
            }
        });
    }
    if (parentFilters) {
        where.push(...parentFilters);
    }

    if (baseFilters) {
        where.push(...baseFilters);
    }
    const requestData = {
        start: page * pageSize,
        limit: isElasticExport ? modelConfig.exportSize : pageSize,
        ...extraParams,
        logicalOperator: filterModel.logicOperator,
        sort: sortModel.map(sort => (sort.filterField || sort.field) + ' ' + sort.sort).join(','),
        where,
        selectedClients,
        oderStatusId: oderStatusId,
        isElasticExport,
        fileName: modelConfig?.overrideFileName,
        fromSelfServe,
        isChildGrid,
        groupBy
    };

    if (lookups) {
        requestData.lookups = lookups.join(',');
    }

    if (modelConfig?.limitToSurveyed) {
        requestData.limitToSurveyed = modelConfig?.limitToSurveyed
    }
    if (modelConfig?.includeColumns) {
        requestData.columns = gridColumns;
    }

    const headers = {};
    let url = controllerType === 'cs' ? isDetailsExport ? `${apis.urlWithControllers}${api}` : `${apis.urlWithControllers}${api}?action=${action}&asArray=0` : `${apis.url}/${api}/${action}`;

    if (template !== null) {
        url += `&template=${template}`;
    }
    if (configFileName !== null) {
        url += `&configFileName=${configFileName}`;
    }

    if (modelConfig?.customApi) {
        url = modelConfig?.customApi
    }

    let params = {
        exportFileName: modelConfig?.overrideFileName,
        action,
        exportFormat: 'XLSX',
        title: modelConfig?.pageTitle,
        sort: sortModel.map(sort => (sort.filterField || sort.field) + ' ' + sort.sort).join(','),
        TimeOffSet: new Date().getTimezoneOffset()
    }
    if (contentType) {
        if (isDetailsExport) {
            url = url + "?v=" + new Date() + '&' + 'forExport=true';
            let filtersForExport = utils.createFilter(filterModel, true);
            if (Object.keys(filtersForExport).length > 0 && params.title !== constants.surveyInboxTitle) {
                filtersForExport.map((item) => {
                    if (item?.operatorValue) {
                        if (item.isValueADate) {
                            let operatorId = utils.dateOperator[item?.operatorValue];
                            if (operatorId?.length > 0) {
                                params.OperatorId = operatorId;
                            }
                        }
                    }
                    params = { ...params, ...item };
                })
            }


        }
        if (where.length && modelConfig?.convertFiltersToPortalFormat) {
            let exportFilters = {};
            if (where.length <= 1) {
                for (const i in where) {
                    where[i] = {
                        "fieldName": where[i].field,
                        "operatorId": utils.filterType[where[i].operator],
                        "convert": false,
                        "values": [where[i].value]
                    }
                }
            } else {
                const filterModelCopy = filterModel;
                let firstFilter = where[0];
                if (filterModelCopy?.items?.length > 1 && firstFilter) {
                    filterModelCopy.items = where;
                    if (firstFilter) {
                        firstFilter = {
                            "fieldName": firstFilter.field,
                            "operatorId": utils.filterType[firstFilter.operator],
                            "convert": false,
                            "values": [firstFilter.value]
                        }
                    }
                    exportFilters = utils.createFilter(filterModel);
                    exportFilters = utils.addToFilter(firstFilter, exportFilters, filterModelCopy?.logicOperator.toUpperCase());
                }

            }
            params['filter'] = Object.keys(exportFilters).length > 0 ? Object.assign({}, exportFilters) : where[0] || '';
        }
        const form = document.createElement("form");
        requestData.responseType = contentType;
        requestData.columns = columns;
        requestData.userTimezoneOffset = new Date().getTimezoneOffset() * -1;
        form.setAttribute("method", "POST");
        form.setAttribute("id", "exportForm");
        form.setAttribute("target", "_blank")
        let arr = isDetailsExport && action === 'export' ? params : requestData
        if (template === null) {
            for (const key in arr) {
                let v = arr[key];
                if (v === undefined || v === null) {
                    continue;
                } else if (typeof v !== 'string') {
                    v = JSON.stringify(v);
                }
                let hiddenTag = document.createElement('input');
                hiddenTag.type = "hidden";
                hiddenTag.name = key;
                hiddenTag.value = v;
                form.append(hiddenTag);
            }
        }

        form.setAttribute('action', url);
        document.body.appendChild(form);
        form.submit();
        setTimeout(() => {
            document.getElementById("exportForm").remove();
        }, 3000)
        return;
    }
    try {
        let params = {
            url,
            method: 'POST',
            data: requestData,
            headers: {
                "Content-Type": "application/json",
                ...headers
            },
            credentials: 'include'
        };

        const response = await transport(params);
        if (response.status === HTTP_STATUS_CODES.OK) {
            const { records, userCurrencySymbol } = response.data;
            if (records) {
                records.forEach(record => {
                    if (record.hasOwnProperty("TotalOrder")) {
                        record["TotalOrder"] = `${userCurrencySymbol}${record["TotalOrder"]}`;
                    }
                    dateColumns.forEach(column => {
                        const { field, keepUTC } = column;
                        if (record[field]) {
                            record[field] = keepUTC ? dayjs.utc(record[field]) : new Date(record[field]);
                        }
                    });
                });
            }
            setData(response.data);
            if (setFetchData)
                setFetchData(true);
        } else {
            setError(response.statusText);
        }
    } catch (err) {
        setError(err);
    } finally {
        if (!contentType) {
            setIsLoading(false);
            if (showFullScreenLoader) {
                dispatch({ type: 'UPDATE_LOADER_STATE', loaderOpen: false });
            }
        }
    }
};

const getRecord = async ({ api, id, setIsLoading, setActiveRecord, modelConfig, parentFilters, where = {}, setError }) => {
    api = api || modelConfig?.api
    setIsLoading(!modelConfig?.overrideLoaderOnInitialRender);

    const searchParams = new URLSearchParams();
    const url = `${apis.url}/${api}/${id === undefined || id === null ? '-' : id}`;
    const lookupsToFetch = [];
    const fields = modelConfig.formDef || modelConfig.columns;
    fields?.forEach(field => {
        if (field.lookup && !lookupsToFetch.includes(field.lookup)) {
            lookupsToFetch.push(field.lookup);
        }
    });
    searchParams.set("lookups", lookupsToFetch);
    if (where && Object.keys(where).length) {
        searchParams.set("where", JSON.stringify(where));
    };
    try {
        const response = await transport({
            url: `${url}?${searchParams.toString()}`,
            method: 'GET',
            credentials: 'include'
        });
        if (response.status === HTTP_STATUS_CODES.OK) {
            const { data: record, lookups } = response.data;
            let title = record[modelConfig.linkColumn];
            const columnConfig = modelConfig.columns.find(a => a.field === modelConfig.linkColumn);
            if (columnConfig && columnConfig.lookup) {
                if (lookups && lookups[columnConfig.lookup] && lookups[columnConfig.lookup].length) {
                    const lookupValue = lookups[columnConfig.lookup].find(a => a.value === title);
                    if (lookupValue) {
                        title = lookupValue.label;
                    }
                }
            }

            const defaultValues = { ...modelConfig.defaultValues };

            setActiveRecord({ id, title: title, record: { ...defaultValues, ...record, ...parentFilters }, lookups });
        } else if (response.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
            setError('Session Expired!');
            setTimeout(() => {
                window.location.href = '/';
            }, 2000);
        } else {
            setError('Could not load record', response.body.toString());
        }
    } catch (error) {
        setError('Could not load record', error);
    } finally {
        setIsLoading(false);
    }
};

const deleteRecord = async function ({ id, api, setIsLoading, setError, setErrorMessage }) {
    let result = { success: false, error: '' };
    if (!id) {
        setError('Deleted failed. No active record.');
        return;
    }
    setIsLoading(true);
    try {
        const response = await transport({
            url: api.includes(apis.url) ? `${api}/${id}` : `${apis.url}/${api}/${id}`,
            method: 'DELETE',
            credentials: 'include'
        });
        if (response.status === HTTP_STATUS_CODES.OK) {
            result.success = true;
            return true;
        }
        if (response.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
            setError('Session Expired!');
            setTimeout(() => {
                window.location.href = '/';
            }, 2000);
        } else {
            setError('Delete failed', response.body);
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.error;
        result.error = errorMessage;
        setErrorMessage(errorMessage);
    } finally {
        setIsLoading(false);
    }
    return result;
};

const saveRecord = async function ({ id, api, values, setIsLoading, setError }) {
    let url, method;

    if (id) {
        url = `${apis.url}/${api}/${id}`;
        method = 'PUT';
    } else {
        url = api;
        method = 'POST';
    }


    try {
        setIsLoading(true);
        const response = await transport({
            url,
            method,
            data: values,
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        });
        if (response.status === HTTP_STATUS_CODES.OK) {
            const { data = {} } = response.data;
            if (data.success) {
                return data;
            }
            setError('Save failed', data.err || data.message);
            return;
        }
        if (response.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
            setError('Session Expired!');
            setTimeout(() => {
                window.location.href = '/';
            }, 2000);
        } else {
            setError('Save failed', response.body);
        }
    } catch (error) {
        setError('Save failed', error);
    } finally {
        setIsLoading(false);
    }

    return false;
};

export {
    getList,
    getRecord,
    deleteRecord,
    saveRecord
};
