import { useEffect } from "react";
import { apis, request } from "../httpUtil";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import constants from "./constants";

const freshChatScript = constants.freshChatScript;

const useFreshChat = ({ sidebarToggleMobile, mobile, loginData }) => {
  const host = process.env.REACT_APP_HOST, history = useHistory(), dispatch = useDispatch();

  useEffect(() => {
    const fcFrame = document.getElementById("fc_frame");
    if (fcFrame && mobile) {
      let isLoginPage = constants.freshChatScreens.includes(window.location.hash?.split('/')[1]);
      if (isLoginPage) {
        window.fcWidget.show();
      } else {
        sidebarToggleMobile ? window.fcWidget.show() : window.fcWidget.hide();
      }
    }

    const onlyForProd = host && (host.includes('portal') || host.includes('dev')),
      scriptId = 'freshChat-script',
      existingScript = document.getElementById(scriptId),
      isLoginPage = constants.freshChatScreens.includes(window.location.hash?.split('/')[1]),
      isWidgetPresent = !!existingScript?.getAttribute("widgetid");

    let initializeBot = false, removeWidget = false;

    if (isLoginPage && !isWidgetPresent) {
      initializeBot = true;
      removeWidget = true;
    }

    if (!isLoginPage && isWidgetPresent) {
      initializeBot = true;
      removeWidget = true;
    }

    if (!existingScript) {
      initializeBot = true;
    }

    if (removeWidget && existingScript) {
      existingScript.remove();
    }

    try {
      if (initializeBot && onlyForProd) {
        if (window?.fcWidget) {
          window.fcWidget.destroy();
        }
        const { Username, FirstName, LastName, FreshChatRestoreId, ClientUserId, ClientName } = loginData?.tags || {};

        const WIDGET_CONFIG = {
          siteId: 'CoolR-Playbook-V3',
          externalId: Username,
          restoreId: FreshChatRestoreId ? FreshChatRestoreId : null,
          config: {
            cssNames: {
              widget: "custom_fc_frame"
            }
          }
        }


        window.fcSettings = {

          onInit: function () {
            if (mobile) {
              window.fcWidget.on("widget:loaded", function (resp) {
                if (isLoginPage) {
                  window.fcWidget.show();
                } else {

                  document.getElementById("fc_frame").classList.add("custom_fc_frame");
                  window.fcWidget.hide();
                }
              });
            }


            window.fcWidget.setExternalId(Username);
            window.fcWidget.user.setFirstName(FirstName);
            window.fcWidget.user.setLastName(LastName);
            window.fcWidget.user.setEmail(Username);
            window.fcWidget.user.setProperties({ last_source: ClientName });

            window.fcWidget.user.get(function (resp) {
              const status = resp && resp.status;
              if (status !== 200) {
                window.fcWidget.user.setProperties({
                  firstName: FirstName,
                  lastName: LastName,
                  email: Username
                });
                window.fcWidget.on('user:created', function (resp) {
                  const status = resp && resp.status, data = resp && resp.data;
                  if (status === 200) {
                    if (data?.restoreId && data.externalId === Username) {
                      const url = `${apis.ClientUser}?action=updateRestoreId&clientUserId=${ClientUserId}&freshChatRestoreId=${data.restoreId}`;
                      request({ url: url, additionalParams: { method: "GET" }, history, dispatch })
                        .then((result) => { })
                        .catch((err) => { })
                    }
                  }
                });
              }
            });
          }
        }

        if (mobile) {
          if (!isLoginPage) {
            window.fcWidgetMessengerConfig = WIDGET_CONFIG;
          } else {
            delete WIDGET_CONFIG["config"];
            window.fcWidgetMessengerConfig = WIDGET_CONFIG
          }
        } else {
          delete WIDGET_CONFIG["config"];
          window.fcWidgetMessengerConfig = WIDGET_CONFIG
        }
        const script = document.createElement('script');
        script.src = freshChatScript + `?version=${Date.now()}`;
        script.id = scriptId;
        script.async = true;
        if (isLoginPage) {
          script.setAttribute('widgetId', '4ed457ee-c20f-407e-866a-36de4c43f6b7');
        }
        document.body.appendChild(script);
      }
    } catch (error) {
      console.error(`FreshChat Initialization Error -> ${error}`);
    }
  }, [loginData, mobile, sidebarToggleMobile]);
};

export default useFreshChat;
