import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, Checkbox, Chip, OutlinedInput, Tooltip, Box } from '@mui/material';
import utils from '../../utils';
import { useTranslation } from 'react-i18next';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function MultiSelectDropdown({ config, options, selectedOptions, handleChange, width }) {
    const t = utils.t;
    const { t: translate, i18n } = useTranslation();
    const tOpts = { t: translate, i18n };

    return (
        <div>
            <FormControl sx={{ m: 1, width: width || 300 }}>
                <InputLabel id="demo-multiple-chip-label">{t(config.label, tOpts)}</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    name={config.label}
                    value={selectedOptions}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label={t(config.label, tOpts)} />}
                    renderValue={(selected) => (
                        <Tooltip
                            title={selected.map((key) => {
                                const selectedItem = options.find(item => (item.Key || item.LookupId) === key);
                                return selectedItem ? t(selectedItem.Value || selectedItem.DisplayValue, tOpts) : '';
                            }).join(', ')}
                            placement="bottom"
                        >
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.1 }}>
                                {(selected.length > 1) ?
                                    <Chip className='multi-select-dropdown-chip' label={t(config.multiSelectLabel || "Multiple selected", tOpts)} />
                                    :
                                    selected.map((key) => {
                                        const selectedItem = options.find(item => (item.Key || item.LookupId) === key);
                                        const labelToShow = (t(selectedItem?.Value || selectedItem?.DisplayValue || '', tOpts) || '').slice(0, 25) + ((t(selectedItem?.Value || selectedItem?.DisplayValue || '', tOpts) || '').length > 25 ? '...' : '');
                                        return (
                                            <Chip className='multi-select-dropdown-chip' key={key} label={t(labelToShow, tOpts)} />
                                        );
                                    })
                                }
                            </Box>
                        </Tooltip>
                    )}
                    MenuProps={MenuProps}
                >
                    {config.titleMessage && <MenuItem value={-1}>{t(config.titleMessage, tOpts)}</MenuItem>}
                    {options.map((item) => (
                        <MenuItem key={item.Key || item.LookupId} value={item.Key || item.LookupId} >
                            <Checkbox checked={selectedOptions.indexOf(item.Key || item.LookupId) > -1} />
                            {(item.Value || item.DisplayValue) ? t((item.Value || item.DisplayValue || '').slice(0, 25) + ((item.Value || item.DisplayValue || '').length > 25 ? '...' : ''), tOpts) : ''}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}