import * as React from 'react';
import { BarPlot } from '@mui/x-charts/BarChart';
import { useXScale, useYScale } from '@mui/x-charts/hooks';
import {
    ResponsiveChartContainer,
    ChartsTooltip,
    ChartsXAxis,
    ChartsYAxis,
} from '@mui/x-charts';
import { Paper, Box, Stack } from '@mui/material';
import { Typography } from 'antd';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme();
const StyledToolTip = styled('div')(({ theme }) => ({
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    fontSize: theme.typography.body2.fontSize,
    marginLeft: '16px',
}));

const ToolTip = ({ value, series }) => {
    return (
        <StyledToolTip>
            {value.map((val, index) => (
                <div key={index} style={{ marginBottom: 4 }}>
                    <span style={{ marginRight: 20 }}>{series}</span>
                    <span>{val}%</span>
                </div>
            ))}
        </StyledToolTip>
    );
};

function Indicators({ values }) {
    const xScale = useXScale();
    const yScale = useYScale();

    return values.map((value, index) => {
        const x = xScale(value);
        const y = yScale(yScale.domain()[index]);
        const bandwidth = yScale.bandwidth();
        return (
            <React.Fragment key={index} >
                <text x={x} y={y} fill="black" dy="-5" textAnchor="middle" fontSize="12px"  > {value}%   </text>
                <line x1={x} y1={y} x2={x} y2={y + bandwidth} stroke="black" stroke-width="3px" />
            </React.Fragment>
        );
    });
}

export default function XComplianceChart({ data, categories, title }) {
    const numberOfBars = categories.length;
    const redData = new Array(numberOfBars).fill(30);
    const orangeData = new Array(numberOfBars).fill(40);
    const greenData = new Array(numberOfBars).fill(30);
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', height: 350 }} elevation={3}>
                    <Stack direction="column" alignItems="center" justifyContent="center" fontWeight="bold" spacing={1}>
                        <Typography fontWeight="bold">{title}</Typography>
                    </Stack>
                    <ResponsiveChartContainer
                        yAxis={[{ scaleType: 'band', data: categories }]}
                        series={[
                            {
                                type: 'bar',
                                stack: 'background',
                                layout: 'horizontal',
                                color: 'red',
                                data: redData,
                            },
                            {
                                type: 'bar',
                                layout: 'horizontal',
                                stack: 'background',
                                color: 'orange',
                                data: orangeData,
                            },
                            {
                                type: 'bar',
                                layout: 'horizontal',
                                stack: 'background',
                                color: 'green',
                                data: greenData,
                            },
                        ]}
                        skipAnimation
                        grid={{ vertical: true }}
                        margin={{ left: 180 }}
                    >
                        <BarPlot tooltip={{ trigger: 'axis' }} />
                        <Indicators values={data} />
                        <ChartsXAxis />
                        <ChartsYAxis />
                        <ChartsTooltip
                            slots={{
                                axisContent: (props) => {
                                    return (
                                        <ToolTip
                                            value={[data[props.dataIndex]]}
                                            series={props.axisValue}
                                        />
                                    );
                                },
                            }} />
                    </ResponsiveChartContainer>
                </Paper>
            </Box>
        </ThemeProvider>
    );
}
