import * as React from 'react';
import { Paper, Box } from '@mui/material';
import { BarPlot } from '@mui/x-charts/BarChart';
import { ResponsiveChartContainer, ChartsXAxis, ChartsYAxis, ChartsTooltip, ChartsLegend } from '@mui/x-charts';
import utils from '../../utils';

const palette = Object.values(utils.analysisColorCodes);
export default function XStackedChart({ data, categories, xLabel, yLabel }) {
    if (!data) return null;
    const series = data.map((entry) => ({
        type: 'bar',
        data: entry?.data || [],
        label: entry?.name || '',
        stack: 'common'
    }));
    return (
        <Box sx={{ width: '100%', height: '50vh', margin: 0, padding: 0 }} className="main-xchart-container">
            <Paper sx={{ width: '100%', height: '100%', margin: 0, padding: 0 }} elevation={3}>
                <ResponsiveChartContainer
                    series={series}
                    xAxis={[{
                        id: 'barCategories',
                        scaleType: 'band',
                        data: categories
                    }]}
                    yAxis={[{
                        id: 'barYAxis',
                        scaleType: 'linear',
                        label: 'Value'
                    }]}
                    colors={palette}
                >
                    <BarPlot />
                    <ChartsXAxis label={xLabel} position="bottom" axisId="barCategories" />
                    <ChartsYAxis label={yLabel} position="left" axisId="barYAxis" />
                    <ChartsTooltip trigger='item' />
                    <ChartsLegend />
                </ResponsiveChartContainer>
            </Paper>
        </Box >
    );
}
