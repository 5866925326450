import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from "@material-ui/core";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import monthlyInvoiceModel from '../monthly-invoice';
import monthlyAssetsStatusModel from '../monthly-assets-status';
import { apis } from "../../httpUtil";
import request, { transport } from '../../httpUtil/httpRequest';
import utils from '../../utils';
import { useTranslation } from 'react-i18next';

const Invoicing = () => {
    const dispatch = useDispatch();
    const [currentInvoiceMonth, setCurrentInvoiceMonth] = useState({ value: 0, startDate: null, endDate: null });
    const [invoiceMonths, setInvoiceMonths] = useState([]);
    const [currencySymbol, setCurrencySymbol] = useState(null);
    const userData = useSelector(state => state.appReducer.userData);
    const { CurrencySymbol } = userData.tags;
    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };

    const defaultFilters = [{ field: 'Date', operator: 'onOrAfter', value: currentInvoiceMonth.startDate }, { field: 'Date', operator: 'onOrBefore', value: currentInvoiceMonth.endDate }];

    const handleChange = (event, newValue) => {
        if (currentInvoiceMonth.value !== newValue) {
            setCurrentInvoiceMonth({ value: newValue, startDate: invoiceMonths[newValue].startDate, endDate: invoiceMonths[newValue].endDate });
        }
    };
    const currencyNameToSymbol = {
        'Euro': '€',
        'Czech Koruna': 'Kč',
        'Danish krone': 'kr',
        'Dollar': '$',
        'hungarian forint': 'Ft',
        'INR': '₹',
        'Israeli Shekel': '₪',
        'Mexican Peso': '$',
        'Philippine peso': '₱',
        'Polish Zloty': 'zł',
        'Pound': '£',
        'Swedish Krone': 'kr',
        'Swiss Franc': 'CHF',
        'Turkish Lira': '₺',
        'UAE Dirham': 'د.إ',
        'Yen': '¥',
    };
    const extractCurrencySymbol = (records) => {
        if (records && records.length > 0) {
            const currencyName = records[0][2];
            const symbol = currencyNameToSymbol[currencyName] || CurrencySymbol;
            return symbol;
        }
        return null;
    };


    async function getInvoiceMonths() {
        const headers = {};
        let params = {
            url: `${apis.MonthlyInvoice}/list`,
            method: 'POST',
            data: { size: 0, limit: 0 },
            headers: {
                "Content-Type": "application/json",
                ...headers
            },
            credentials: 'include'
        };
        let param = {
            url: apis.Pricing,
            params: {
                start: 0,
                limit: 20,
                comboTypes: [{ "type": "RevenueCurrency", "loaded": false }],
                action: 'list',
                asArray: 1
            }, dispatch
        };
        const response = await transport(params);
        const data = await request(param);
        if (response.data.aggregations) {
            setInvoiceMonths(response.data.aggregations);
            const lastMonth = response.data.aggregations[response.data.aggregations.length - 1];
            setCurrentInvoiceMonth({ value: response?.data.aggregations.length - 1, startDate: lastMonth?.startDate, endDate: lastMonth?.endDate });
            const symbol = extractCurrencySymbol(data.records);
            setCurrencySymbol(symbol);
        }
    }

    useEffect(() => {
        getInvoiceMonths();
    }, [])

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '15px' }}>
                <Box sx={{ maxWidth: { xs: 320, sm: 1000 } }}>
                    <Tabs
                        sx={{ height: 65 }}
                        value={currentInvoiceMonth.value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs"
                    >
                        {invoiceMonths?.map(ele => {
                            const { key, value } = ele;
                            let parts = key.split(" ");
                            parts[0] = t(parts[0], tOpts);
                            let keyTranslated = parts.join(" ");
                            return (<Tab
                                sx={{ padding: '0px', backgroundColor: 'white', height: 65, margin: '0% 1% 0% 1%' }}
                                key={key}
                                label={<React.Fragment>
                                    <span style={{ fontSize: "small", marginBottom: 9 }}>{keyTranslated}</span>
                                    <span style={{ fontSize: "large" }}>{currencySymbol} {utils.totalCostFormatter(value)}</span>
                                </React.Fragment>}
                            />)
                        })}
                    </Tabs>
                </Box>
            </div>
            <Grid container spacing={1}>
                <Grid item style={{ width: '100%', marginTop: '1%' }}>
                    <monthlyInvoiceModel.Grid reRenderKey={currentInvoiceMonth.value} additionalFilters={defaultFilters} />
                </Grid>

                <Grid item style={{ width: '100%', marginTop: '1%' }}>
                    <monthlyAssetsStatusModel.Grid reRenderKey={currentInvoiceMonth.value} additionalFilters={defaultFilters} />
                </Grid>
            </Grid>
        </div >
    )
}

export default Invoicing;