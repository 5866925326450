import React, { useState } from 'react';
import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, ListItemText, MenuItem, Modal, OutlinedInput, Paper, Radio, RadioGroup, Select, Stack, TextField, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import { Clear } from '@mui/icons-material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import utils from '../../../utils';
import swal from 'sweetalert';
import { useSnackbar } from "@durlabh/dframework-ui";
import constants from '../../../utils/constants';
import { ALERTS } from '../../../constants';

const { CREATOR_QUESTION_DISPLAYNAME, QUESTION_TYPES } = constants;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        padding: theme.spacing(3),
        width: "70%"
    },
    formControl: {
        width: '180px'
    },
    questionSelect: {
        backgroundColor: '#E7EDF6',
        '& .MuiOutlinedInput-input': {
            padding: '15px',
            '&::placeholder': {
                color: 'white'
            }
        }
    },
    operatorSelect: {
        backgroundColor: '#FAF0E2',
        '& .MuiOutlinedInput-input': {
            padding: '15px',
            '&::placeholder': {
                color: 'white'
            },
        }
    },
    inputLabel: {
        color: 'white'
    },
    rightHeaderBtn: {
        position: 'absolute',
        right: '0px',
        top: '10px'
    },
    customFormControl: {
        width: '90% !important',
        marginLeft: '10px !important'
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 300
        }
    },
    autoFocus: false
};

const DefineConditionModal = ({ open, handleClose, questions, qid, onApplyConditions, questionsToSelect, existingConditions, onRemoveDependency, generateAlert, repeatQuestion = false }) => {
    const classes = useStyles();
    const [updatedQuestions, setUpdatedQuestions] = React.useState(existingConditions);
    const [cleanedQuestions, setQuestionsToSelect] = React.useState([]);
    const [selectedCheckboxOption, setSelectedCheckboxOption] = React.useState([]);
    const [isAllQuestions, setIsAllQuestions] = React.useState(true);
    const t = utils.t
    const { t: translate, i18n } = useTranslation()
    const tOpts = { t: translate, i18n };
    const [selectedQuestion, setSelectedQuestion] = useState(questions[qid]);
    const snackbar = useSnackbar();

    React.useEffect(() => {
        setSelectedQuestion(questions[qid])
    }, [questions[qid]]);

    React.useEffect(() => {
        setUpdatedQuestions(existingConditions);
        setSelectedQuestion(questions[qid]);
    }, [existingConditions]);

    React.useEffect(() => {
        getPredefinedCondition();
    }, [questions[qid]]);

    React.useEffect(() => {
        if (repeatQuestion) {
            setSelectedCheckboxOption([]);
            if (selectedQuestion) {
                if (questions[qid]?.repeat) {
                    setIsAllQuestions(false);
                    setSelectedCheckboxOption(questions[qid]?.repeat || []);
                } else {
                    setIsAllQuestions(true);
                }
            }
            setUpdatedQuestions([selectedQuestion])
        }
    }, [open]);

    React.useEffect(() => {
        if (generateAlert) {
            setUpdatedQuestions([selectedQuestion])
        }
    }, [generateAlert]);



    const getQuestionOperators = (type) => {
        switch (type) {
            case 'radio':
                return [
                    { type: 'Equals', value: '=' },
                    { type: 'Not Equals', value: '!=' },
                    { type: 'Empty', value: 'empty' },
                    { type: 'Not Empty', value: 'not_empty' }
                ];
            case 'boolean':
                return [
                    { type: 'Equals', value: '=' },
                    { type: 'Not Equals', value: '!=' },
                    { type: 'Empty', value: 'empty' },
                    { type: 'Not Empty', value: 'not_empty' }
                ];
            case 'checkbox':
                return [
                    { type: 'Empty', value: 'empty' },
                    { type: 'Not Empty', value: 'not_empty' },
                    { type: 'In', value: 'in' },
                    { type: 'Not In', value: 'not_in' }
                ];
            case 'date':
                return [
                    { type: 'Greater Than', value: '>' },
                    { type: 'Greater Than or Equal To', value: '>=' },
                    { type: 'Less Than', value: '<' },
                    { type: 'Less Than or Equal To', value: '<=' }
                ];
            case 'number':
                return [
                    { type: 'Greater Than', value: '>' },
                    { type: 'Greater Than or Equal To', value: '>=' },
                    { type: 'Less Than', value: '<' },
                    { type: 'Less Than or Equal To', value: '<=' }
                ];
            case 'barcode':
                return [
                    { type: 'Empty', value: 'empty' },
                    { type: 'Not Empty', value: 'not_empty' }
                ];
            case 'image':
                return [
                    { type: 'Empty', value: 'empty' },
                    { type: 'Not Empty', value: 'not_empty' }
                ];
            default:
                return [];
        }
    }

    const onSaveDependencyCondition = async () => {
        if (repeatQuestion) {
            if (selectedQuestion && selectedQuestion?.repeatkey) {
                onApplyConditions(selectedQuestion, isAllQuestions, selectedCheckboxOption);
            } else {
                snackbar.showError(`${t("You have to select a value to apply repeat functionality.", tOpts)}`);
            }
        } else if (generateAlert) {
            if (selectedQuestion && selectedQuestion?.answerkey) {
                onApplyConditions(selectedQuestion);
            } else {
                snackbar.showError(`${t("You have to select a value to apply alert functionality.", tOpts)}`);
            }
        } else {
            if (updatedQuestions.length > 0) {
                try {
                    if (updatedQuestions[0].hasOwnProperty('answerkey')) {
                        onApplyConditions(updatedQuestions);
                    } else {
                        snackbar.showError(`${t("You have to select a value to apply a dependency.", tOpts)}`);
                    }

                } catch (error) {
                    window.onerror("Error adding dependent condition:", error);
                    snackbar.showError(`${t("Error adding dependent condition, please try after some time.", tOpts)}`);
                }
            } else {
                snackbar.showError(`${t("You have to select a dependent question in order to apply", tOpts)}`);
            }
        }
    }

    const handleOptionInputChange = (e, qid) => {
        let { value } = e.target;
        if (!value) return;
        const updatedItem = repeatQuestion || generateAlert ? { ...selectedQuestion } : null;
        if (repeatQuestion || generateAlert) {
            const keyToUpdate = repeatQuestion ? 'repeatkey' : 'answerkey';
            updatedItem[keyToUpdate] = [Number(value)];
            setSelectedQuestion(updatedItem);
        } else {
            const updatedConditionArray = updatedQuestions.map((item) => {
                if (item.id === qid) {
                    return { ...item, answerkey: [Number(value)] };
                }
                return item;
            });
            setUpdatedQuestions(updatedConditionArray);
        }
    };

    const handleCheckboxInputChange = (e, qid) => {
        let { value, type } = e.target;
        const currentSelectedOptions = repeatQuestion ? selectedQuestion.repeatkey || [] : generateAlert ? selectedQuestion.answerkey || [] : updatedQuestions
            .find((item) => item.id === qid)
            ?.answerkey || [];

        if (!value) return;

        let updatedSelectedOptions;

        if (type === 'checkbox') {
            if (currentSelectedOptions.includes(Number(value))) {
                updatedSelectedOptions = currentSelectedOptions.filter(
                    (option) => option !== (Number(value))
                );
            } else {
                updatedSelectedOptions = [...currentSelectedOptions, Number(value)];
            }
        } else {
            updatedSelectedOptions = value;
        }
        const updatedItem = repeatQuestion || generateAlert ? { ...selectedQuestion } : null;
        if (repeatQuestion || generateAlert) {
            const keyToUpdate = repeatQuestion ? 'repeatkey' : 'answerkey';
            updatedItem[keyToUpdate] = updatedSelectedOptions;
            setSelectedQuestion(updatedItem);
        } else {
            const updatedConditionArray = updatedQuestions.map((item) => {
                if (item.id === qid) {
                    return { ...item, answerkey: updatedSelectedOptions };
                }
                return item;
            });
            setUpdatedQuestions(updatedConditionArray);
        }
    };



    const getQuestionValue = (q) => {
        const questionValue = repeatQuestion ? q.repeatkey : q.answerkey;
        switch (q.type || q.displayName) {
            case CREATOR_QUESTION_DISPLAYNAME.RADIO:
            case QUESTION_TYPES.RADIO: //'radio':
                return (
                    <FormControl className={classes.customFormControl}>
                        <RadioGroup
                            aria-labelledby="yes-no-select-label"
                            name="controlled-radio-buttons-group"
                            value={questionValue || ''}
                        >
                            {q.options?.map((option, i) => (
                                option.value.length ? (
                                    <FormControlLabel
                                        key={i}
                                        value={option.key}
                                        control={<Radio />}
                                        name={option.value}
                                        disabled={option.disabled}
                                        onChange={(e) => handleOptionInputChange(e, q.id)}
                                        label={option.value}
                                    />
                                ) : null
                            ))}
                        </RadioGroup>
                    </FormControl>
                );
            case QUESTION_TYPES.BOOLEAN: //'boolean':
                return (
                    <FormControl className={classes.customFormControl}>
                        <RadioGroup
                            aria-labelledby="yes-no-select-label"
                            name="controlled-radio-buttons-group"
                            value={questionValue || ''}
                            onChange={(e) => handleOptionInputChange(e, q.id)}
                        >
                            {q.options?.map((option, i) => (
                                <FormControlLabel key={i} value={option.key} control={<Radio />} label={option.value} />
                            ))}
                        </RadioGroup>
                    </FormControl>
                );
            case CREATOR_QUESTION_DISPLAYNAME.CHECKBOX:
            case QUESTION_TYPES.CHECKBOX: //'checkbox':
                return (
                    <FormControl className={classes.customFormControl}>
                        {q.options.map((option, i) => (
                            option.value.length ? (<FormControlLabel
                                key={option.id}
                                value={questionValue || []}
                                onChange={(e) => handleCheckboxInputChange(e, q.id)}
                                control={
                                    <Checkbox
                                        name={option.name}
                                        disabled={false}
                                        checked={q.answerkey?.includes(option.key)}
                                        value={option.key}
                                    />
                                }
                                label={t(option.value, tOpts)}
                            />) : null
                        ))}
                    </FormControl>
                );
            case QUESTION_TYPES.SELECT:
            case CREATOR_QUESTION_DISPLAYNAME.DROPDOWN: //'Dropdown':
                return <>
                    {q.subType === "Multiple Choice" ? (
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="demo-multiple-name-label">
                                {t("Multiple Choice", tOpts)}
                            </InputLabel>
                            <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                multiple
                                value={questionValue || []}
                                input={<OutlinedInput />}
                                onChange={(e) => handleCheckboxInputChange(e, q.id)}
                                renderValue={(selectedKeys) =>
                                    selectedKeys
                                        .map((key) =>
                                            q.options.find((option) => option.key === key)?.value || key
                                        )
                                        .join(", ")
                                }
                                MenuProps={MenuProps}
                                sx={{ width: '150px' }}
                            >
                                {q.options.map((option, o) => (
                                    <MenuItem key={option.value} value={option.key}>
                                        <Checkbox
                                            checked={questionValue?.includes(option.key)}
                                        />
                                        <ListItemText primary={option.value} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : (
                        <FormControl>
                            <Select
                                label='Options'
                                id="demo-simple-select"
                                value={questionValue || ''}
                                onChange={(e) => handleOptionInputChange(e, q.id)}
                                gutterBottom
                                sx={{ width: '150px' }}
                            >
                                {q.options.map((option, o) => (
                                    <MenuItem key={option.value} value={option.key}>
                                        <ListItemText primary={option.value} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </>
            default:
                return <></>;
        }
    }

    const removeQuestionFromConditionSet = async (index) => {
        try {
            const userConfirmed = await swal({
                title: `${t("Are you sure?", tOpts)}`,
                text: `${t("You are about to remove this dependency", tOpts)}`,
                icon: "warning",
                buttons: true,
                dangerMode: true
            });
            if (userConfirmed) {
                const clonedItems = [...updatedQuestions];
                const dependentQuestion = clonedItems[index].questionUniqueId;
                setQuestionsToSelect(prev => [...prev, clonedItems[index]]);
                clonedItems.splice(index, 1);
                setUpdatedQuestions(clonedItems);
                onRemoveDependency(selectedQuestion.questionUniqueId, dependentQuestion);
            }
        } catch (error) {
            snackbar.showError(`${t("Error removing dependency.", tOpts)}`);
        }
    }

    const removeDependency = () => {
        const { answerkey, generateAlert, ...updatedQuestion } = selectedQuestion;
        delete updatedQuestion.repeatkey
        setSelectedQuestion(updatedQuestion);
        onRemoveDependency();
    }

    const getPredefinedCondition = () => {
        if (generateAlert && questions[qid]) {
            if (questions[qid][ALERTS.key]) {
                let answerkey = null;
                switch (selectedQuestion.type || selectedQuestion.displayName) {
                    case CREATOR_QUESTION_DISPLAYNAME.CHECKBOX:
                        answerkey = questions[qid][ALERTS.key]["answerkey"];
                        break;
                    default:
                        answerkey = Array.isArray([questions[qid][ALERTS.key]]) ? [questions[qid][ALERTS.key]] : [questions[qid][ALERTS.key]["answerkey"][0]];
                        break;
                }
                setSelectedQuestion(prevQuestion => ({
                    ...prevQuestion,
                    answerkey: answerkey
                }));
            } else {
                const { answerkey, ...updatedQuestion } = questions[qid];
                setSelectedQuestion(updatedQuestion);
            }
        } else if (repeatQuestion && questions[qid]) {
            if (questions[qid][ALERTS.key]) {
                let answerkey = null;
                switch (selectedQuestion.type || selectedQuestion.displayName) {
                    case CREATOR_QUESTION_DISPLAYNAME.CHECKBOX:
                        answerkey = questions[qid][ALERTS.key]["answerkey"];
                        break;
                    default:
                        answerkey = [questions[qid][ALERTS.key]["answerkey"][0]];
                        break;
                }
                setSelectedQuestion(prevQuestion => ({
                    ...prevQuestion,
                    answerkey: answerkey
                }));
            } else {
                const { answerkey, ...updatedQuestion } = questions[qid];
                setSelectedQuestion(updatedQuestion);
            }
        }
    }

    const onCancelDependency = () => {
        getPredefinedCondition();
        handleClose();
    }

    const handleSelectQuestion = (event, defaultId = null) => {
        let value = event?.target.value;
        if (defaultId >= 0) {
            value = questions[defaultId];
            setUpdatedQuestions([value]);
        } else {
            const updatedQuestionsToSelect = questionsToSelect?.filter(q => q.id !== value?.id);
            setQuestionsToSelect(updatedQuestionsToSelect);
            setUpdatedQuestions(prev => [...prev, value]);
        }


    };

    const handleOperatorChange = (qid, event) => {
        const selectedOperator = event.target.value;
        const updatedConditionArray = updatedQuestions.map((item) =>
            item.id === qid ? { ...item, operator: selectedOperator } : item
        );
        setUpdatedQuestions(updatedConditionArray);
    }

    const handleVisibilityChange = (qid, event) => {
        const showOption = event.target.value;
        const updatedConditionArray = updatedQuestions.map((item) =>
            item.id === qid ? { ...item, visibility: showOption } : item
        );
        setUpdatedQuestions(updatedConditionArray);
    }

    const handleRepeatChange = (event) => {
        const itemQuestionId = event.currentTarget.getAttribute('data-itemlookupid');
        let newCheckedItems = [...selectedCheckboxOption];
        if (newCheckedItems.includes(itemQuestionId)) {
            newCheckedItems = newCheckedItems.filter(id => id !== itemQuestionId);
        } else {
            newCheckedItems.push(itemQuestionId);
        }
        setSelectedCheckboxOption(newCheckedItems);
        setIsAllQuestions(newCheckedItems.length === questionsToSelect.length);
    };

    const handleAllOptionChange = (event) => {
        const isChecked = event.target.checked;
        setIsAllQuestions(isChecked);
        if (isChecked) {
            const selectedValues = questionsToSelect.map(q => q.id).filter(Boolean)
            setSelectedCheckboxOption(selectedValues);
        } else {
            setSelectedCheckboxOption([]);
        }
    }

    return (
        <Modal open={open} onClose={handleClose} className={classes.modal}>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>{repeatQuestion ? t('Define Repeat Condition', tOpts) : generateAlert ? t("Add Alert Condition", tOpts) : t('Add Dependency Condition', tOpts)}</Typography>
                        <Stack direction="row" spacing={2} justifyContent="flex-end" className={`${classes.rightHeaderBtn}`}>
                            <Button variant="contained" type="submit" color="success" onClick={onSaveDependencyCondition}>{t('Apply', tOpts)}</Button>
                            <Button variant="contained" type="cancel" color="error" onClick={onCancelDependency} >{t('Cancel', tOpts)}</Button>
                        </Stack>
                        {!repeatQuestion && !generateAlert ? <Paper elevation={0} sx={{ marginTop: 3 }}>
                            {/* <Typography className='float-right'>{t('Default Visibility:', tOpts)} {selectedQuestion.isVisible !== false ? 'Show' : 'Hide'} </Typography> */}
                            <Typography gutterBottom>Q. {questions[qid].text}</Typography>
                        </Paper> : null}
                    </Grid>
                    {updatedQuestions.length > 0 ?
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>{t(`${repeatQuestion ? 'Repeat' : generateAlert ? '' : 'Parent'} Question`, tOpts)}</TableCell>
                                        {/* <TableCell align="left">{t('Options/Value', tOpts)}</TableCell> */}
                                        <TableCell align="left">{t('Selected Value', tOpts)}</TableCell>
                                        {/* <TableCell align="left">{t('Visibility', tOpts)}</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {repeatQuestion || generateAlert ?
                                        <TableBodyComponent q={selectedQuestion} repeatQuestion={repeatQuestion} removeQuestion={removeDependency} getQuestionValue={getQuestionValue} t={t} tOpts={tOpts} generateAlert={generateAlert} />
                                        :
                                        updatedQuestions.map((q, i) => (
                                            <TableBodyComponent q={q} repeatQuestion={repeatQuestion} removeQuestion={removeQuestionFromConditionSet} getQuestionValue={getQuestionValue} t={t} tOpts={tOpts} i={i} generateAlert={generateAlert} />
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        : null}
					{repeatQuestion || updatedQuestions.length === 0 ?
                    <Paper sx={{ marginTop: 3, width: '100%', padding: 3 }}>
                        <Grid container alignItems="center" spacing={2} xs={12} md={12}>
                            <Grid item xs={4}>
                                <Typography>{t(repeatQuestion ? 'Questions to repeat' : 'Depends on', tOpts)} :</Typography>
                            </Grid>
								{repeatQuestion ?
                            <Grid item xs={8}>
                                    <FormControl sx={{ m: 1, width: '100%' }}>
                                        <InputLabel id="demo-multiple-checkbox-label">{t('Questions to repeat', tOpts)}</InputLabel>
                                        <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={isAllQuestions ? ['All'] : selectedCheckboxOption}
                                        input={<OutlinedInput label="Repeat Questions" />}
                                        renderValue={(selectedKeys) =>
                                            isAllQuestions ? t('All', tOpts) :
                                                selectedKeys
                                                    .map((key) =>
                                                        questionsToSelect.find((q) => q.id === key)?.text || key
                                                    )
                                                    .join(", ")
                                        }
                                        MenuProps={MenuProps}
                                        >
                                        <MenuItem value={'All'} onClick={handleAllOptionChange}>
                                            <Checkbox checked={isAllQuestions || selectedCheckboxOption?.length === questionsToSelect?.length} />
                                            <ListItemText primary={t(`${'All'} `, tOpts)} />
                                        </MenuItem>
                                        {questionsToSelect.map((question, qid) => (
                                            <MenuItem key={qid} value={question.id} data-itemlookupid={question.id} onClick={handleRepeatChange}>
                                            <Checkbox checked={isAllQuestions || selectedCheckboxOption?.includes(question?.id)} />
                                            <ListItemText primary={question.text} />
                                            </MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                </Grid> :
                                    <Grid item xs={8}>
                                        <FormControl sx={{ m: 1, width: '100%' }}>
                                            <InputLabel id="demo-multiple-name-label">{t('Select Dependent Question', tOpts)}</InputLabel>
                                            <Select
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                value={questionsToSelect}
                                                onChange={handleSelectQuestion}
                                                input={<OutlinedInput label="Select Dependent Question" />}
                                                MenuProps={MenuProps}
                                            >
                                                {questionsToSelect?.map((question, qid) => (
                                                    <MenuItem
                                                        key={qid}
                                                        value={question}
                                                    >
                                                        {t(question.text, tOpts)}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>}
                            </Grid>
                        </Paper>
                        : null}
                </Grid>
            </Paper>
        </Modal>
    );
};

const TableBodyComponent = ({ q, repeatQuestion, removeQuestion, getQuestionValue, t, tOpts, i, generateAlert }) => {
    const conditionText = generateAlert ? ' Alert' : repeatQuestion ? 'Repeat condition' : 'Dependency';
    return (
        <TableRow
            key={i || q.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component="th" scope="row">
                <Clear color="error" className='cursor-pointer' onClick={() => removeQuestion(i)} />
            </TableCell>
            <TableCell component="th" scope="row">
                <Typography>{t(q.text, tOpts)}</Typography>
            </TableCell>
            <TableCell align="left">
                <div style={{ maxHeight: 100, overflowY: 'auto' }}>
					{q.dynamicOptions && typeof (q.dynamicOptions.items) === "string" ?
                        <Typography>{t( `${conditionText} not allowed on dynamic question`, tOpts)}</Typography>
                        : getQuestionValue(q)}
                </div>
            </TableCell>

        </TableRow>
    )
}

export default DefineConditionModal;
